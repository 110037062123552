import { useEffect, useState } from 'react';
import './StepJavascriptModal.css';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/javascript-hint';
import { parse } from 'acorn';
import axios from 'axios';

export const StepJavascriptModal = ({step, onClose}) => {
    const [selectedMenu, setSelectedMenu] = useState("Query");
    const [description, setDescription] = useState('');
    const [query, setQuery] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [fixeResponse, setFixeResponse] = useState(false);
    const [variableMemory, setVariableMemory] = useState(null);
    const [libraries, setLibraries] = useState(false);
    const [installedLibraries, setInstalledLibraries] = useState([]);

    useEffect(() => {

        setDescription(step?.description);
        setFixeResponse(Boolean(step?.fixed_response || 0));
        setVariableMemory(step?.constants?.find(item => item.content === 'memory')?.value || '')
        setQuery(step?.constants.find(item => item.content === 'javascript')?.value || '');

        // loadInstalledLibraries();
    }, [step]);  

    useEffect(() => {
        analyzeCode(query);
    }, [query]);

    const analyzeCode = (jsCode) => {
        try {
            // Remover espaços em branco iniciais e finais para evitar erros de análise
            const trimmedCode = jsCode.trim();
    
            // Adicionar uma função de wrapper para encapsular o código, se necessário
            const wrappedCode = `
                (async function() {
                    ${trimmedCode}
                })();
            `;
    
            const ast = parse(wrappedCode, {
                sourceType: 'module',
                ecmaVersion: 'latest',
            });
    
            const librariesSet = new Set();
    
            const findImports = (node) => {
                if (node.type === 'ImportDeclaration') {
                    librariesSet.add(node.source.value);
                } else if (node.type === 'CallExpression' && node.callee.name === 'require') {
                    if (node.arguments[0].type === 'Literal') {
                        librariesSet.add(node.arguments[0].value);
                    }
                } else if (node.type === 'ImportExpression') {  // Para lidar com imports dinâmicos
                    if (node.source.type === 'Literal') {
                        librariesSet.add(node.source.value);
                    }
                }
    
                for (const key in node) {
                    if (node[key] && typeof node[key] === 'object') {
                        findImports(node[key]);
                    }
                }
            };
    
            findImports(ast);
            setLibraries(Array.from(librariesSet) || false);
        } catch (error) {
            console.error("Erro ao analisar o código:", error);
            setLibraries(false);
        }
    };


    const loadInstalledLibraries = async () => {
        try {
            const response = await axios.get('http://services.drogaleste.com:3001/bibliotecas');
            // Assume que o response.data é um array de strings
            setInstalledLibraries(response.data);
        } catch (error) {
            console.error('Erro ao carregar as bibliotecas:', error);
            setInstalledLibraries(false); // Defina como um array vazio em caso de erro ou nenhum dado
        }
    };
    

    const installLibrary = async (library) => {
        try {
            await axios.get(`http://services.drogaleste.com:3001/bibliotecas/install/${library}`);
            loadInstalledLibraries();
        } catch (error) {
            console.error('Erro ao instalar a biblioteca:', error);
        }
    };

    // const isLibraryInstalled = (library) => installedLibraries.includes(library);

    const isLibraryInstalled = (library) => {
        const allLibraries = Object.keys(installedLibraries.dependencies || {});
        return allLibraries.includes(library);
    };
    


    const generateSQLScript = () => {
        const service = step?.service || step?.service || '';
        const action = step?.action || step?.action || 0;
        const escapedDescription = description.replace(/'/g, "''");
        const escapedQuery = query.replace(/'/g, "''");

        return `
            BEGIN TRANSACTION;
            DECLARE @ActionId INT;
            DECLARE @StepId INT;
            IF NOT EXISTS (SELECT 1 FROM SERVICES_ACTIONS WHERE [action] = ${action})
            BEGIN
                INSERT INTO SERVICES_ACTIONS ([status], [service], [description], [code_type], [fixed_response])
                VALUES (1, '${service}', '${escapedDescription}', 3, ${Number(fixeResponse) || '0'});
                SET @ActionId = SCOPE_IDENTITY();
            END
            ELSE
            BEGIN
                SET @ActionId = ${action};
                UPDATE SERVICES_ACTIONS
                SET [description] = '${escapedDescription}'
                ,[fixed_response] = ${`'${Number(fixeResponse) || '0'}'`}
                WHERE [action] = @ActionId;
            END;

            IF NOT EXISTS (SELECT 1 FROM SERVICES_ROUTINES_STEPS WHERE [step] = ${step?.step || 0})
            BEGIN
                INSERT INTO SERVICES_ROUTINES_STEPS ([status], [service], [action], [routine])
                VALUES (1, ${step?.service || step?.service}, @ActionId, ${step?.routine || step?.routine});
                SET @StepId = SCOPE_IDENTITY();
            END
            ELSE
            BEGIN
                SET @StepId = ${step?.step || 0};
            END;

            IF NOT EXISTS (SELECT 1 FROM SERVICES_CONSTANTS WHERE [service] = '${service}' AND [action] = @ActionId AND [content] = 'javascript' AND [field] = 'code')
            BEGIN
                INSERT INTO SERVICES_CONSTANTS ([service], [action], [content], [field], [value])
                VALUES ('${service}', @ActionId, 'javascript', 'code', '${escapedQuery}');
            END
            ELSE
            BEGIN
                UPDATE SERVICES_CONSTANTS
                SET [value] = '${escapedQuery}'
                WHERE [service] = '${service}' AND [action] = @ActionId AND [content] = 'javascript' AND [field] = 'code';
            END;

            IF NOT EXISTS (SELECT 1 FROM SERVICES_CONSTANTS WHERE [service] = '${service}' AND [action] = @ActionId AND [content] = 'memory')
            BEGIN
                INSERT INTO SERVICES_CONSTANTS ([service], [action], [content], [field], [value])
                VALUES ('${service}', @ActionId, 'memory', 'data', '${variableMemory}');
            END
            ELSE
            BEGIN
                UPDATE SERVICES_CONSTANTS
                SET [value] = '${variableMemory}'
                WHERE [service] = '${service}' AND [action] = @ActionId AND [content] = 'memory';
            END;  

            COMMIT TRANSACTION;
        `;
    };



    const handleSave = async () => {
        // Gera o script SQL
        const sqlScript = generateSQLScript();
        console.log(sqlScript)

    }

    return (
        <div className="modal step-javascript">
            <section className='content vert gap-x3'>
                <div className='hori space-between pad-l5 pad-t5 pad-r5'>
                    <div className="close-button" onClick={() => onClose(false)} />
                    <h3>{step ? 'Editar' : 'Adicionar'} Rotina</h3>
                    <h5></h5>
                </div>
                <div className='scrool-y pad-l5 pad-r5' style={{backgroundColor: 'transparent'}}>
                    <div className='vert gap-x3'>
                        <div className='vert'>
                            <div className='vert gap-x2'>
                                <label className='vert'>
                                    <h5>Descrição:</h5>
                                    <input
                                        type="text"
                                        placeholder="Descrição"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className='vert gap-x3'>
                            <div style={{ borderBottom: '3px solid #ebebeb', paddingBottom: '0.1rem' }}>
                                <label className={`tab-item ${selectedMenu === 'Query' ? 'selected' : ''}`} onClick={() => setSelectedMenu('Query')}>Query</label>
                                <label className={`tab-item ${selectedMenu === 'Resultado' ? 'selected' : ''}`} onClick={() => setSelectedMenu('Resultado')}>Resultado</label>
                                <label className={`tab-item ${selectedMenu === 'Conexão' ? 'selected' : ''}`} onClick={() => setSelectedMenu('Conexão')}>Conexão</label>
                                <label className={`tab-item ${selectedMenu === 'Configuração' ? 'selected' : ''}`} onClick={() => setSelectedMenu('Configuração')}>Configuração</label>
                            </div>
                            {selectedMenu === 'Query' && (
                                <div className='vert'>
                                    <CodeMirror
                                        value={query}
                                        options={{
                                            mode: 'javascript',
                                            theme: 'eclipse',
                                            lineNumbers: true,
                                            // readOnly: true,
                                            extraKeys: { "Ctrl-Space": "autocomplete" },
                                            gutters: ["CodeMirror-linenumbers"]
                                        }}
                                        onBlur={(editor, event) => {
                                            const value = editor.getValue();
                                            setQuery(value);
                                        }}
                                        editorDidMount={editor => {
                                            editor.setSize("calc(100% - 0px)", "calc(100vh - 200px)");
                                            editor.getWrapperElement().style.fontFamily = 'Courier New';
                                            editor.getWrapperElement().style.fontSize = '14px';
                                            editor.refresh();
                                        }}
                                    />

                                </div>
                            )}
                        </div>

                    </div>
                </div>
                <div className='pad-x3' style={{backgroundColor: 'whitesmoke'}}>
                    <div className='hori gap-x2 pad-l5 pad-r5 pad-b2'>
                        <button type='save' onClick={handleSave}>Salvar</button>
                        <button type='cancel' onClick={onClose}>Cancelar</button>
                    </div>
                </div>
            </section>
        </div>
    )
}