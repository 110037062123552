import { BsDatabase } from "react-icons/bs";
import { Colors } from "../../../Functions/Colors";
import './CardStepTsqlItem.css';
import { MdLanguage } from "react-icons/md";
import { BiKey, BiServer, BiUser } from "react-icons/bi";
export const CardStepTsqlItem = ({ step }) => {

    const credential = step?.credential;
    // console.log(credential)
    const server = credential.find(ct => ct.field === 'server')?.value;
    const user = credential.find(ct => ct.field === 'user')?.value;
    const password = credential.find(ct => ct.field === 'password')?.value;
    const database = credential.find(ct => ct.field === 'database')?.value;
    // const headersCount = constants?.filter(ct => ct.content === 'headers')?.length;
    // const queryCount = constants?.filter(ct => ct.content === 'query')?.length;
    // const pathsCount = constants?.filter(ct => ct.content === 'path')?.length;
    // const bodyCount =  constants?.filter(ct => ct.content === 'body')?.length;

    const handleSelected = (selected) => {
        
    }

    return (
        <div key={step.step} className="hori gap-x4 pad-x3" onClick={() => handleSelected(step)} style={{display: 'grid', gridTemplateColumns: '45px 1fr'}}>
            
            <div className="vert center-a center-j">
                <div className="no-wrap">
                    <span style={{fontSize: '0.7rem', padding: '0.2rem 0.7rem', color: '#c93e3e', border: '1px solid #c93e3e', borderRadius: '1rem'}}>T-Sql</span>
                </div>
            </div>
            <div className="vert"> 
                <div className="hori">
                {/* <BsDatabase/>   */}
                <h3 style={{ fontWeight: 400, fontSize: 15 }}>{step.description}</h3>
                </div>
                <div className="vert">
                    <div className="hori flex gap-x4" style={{ fontSize: '0.8rem' }}>
                        <div className="hori flex-0 center-a gap-x1" style={{ color: '#959595' }}>
                            <BiServer/> {server}
                        </div>
                        <div className="hori flex-0 center-a gap-x1" style={{ color: '#959595' }}>
                            <BiUser/> {user}
                        </div>
                        <div className="hori flex-0 center-a gap-x1" style={{ color: '#959595' }}>
                            <BiKey/> ********
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    )
}