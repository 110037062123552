import { useState } from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-monokai';

export const Integracao = () => {
  const [language, setLanguage] = useState('javascript');

  const handleLanguageChange = (event) => {
    try {
      setLanguage(event.target.value);
    } catch (error) {
      console.error('Ocorreu um erro ao mudar a linguagem:', error);
    }
  };

  return (
    <>
      <h2>Com base nos objetos obtidos, o fluxo deve executar este código</h2>
      <select value={language} onChange={handleLanguageChange}>
        <option value="javascript">JavaScript</option>
        <option value="sql">T-SQL</option>
      </select>
      <AceEditor
        mode={language}
        theme="monokai"
        name="editor"
        editorProps={{ $blockScrolling: true }}
      />
    </>
  );
};
