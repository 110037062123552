import React, { useState } from 'react';
import JsonView from '@uiw/react-json-view';
import './Requisicao.css';

export const Requisicao = ({ selectedIntegration, selectedRouter }) => {
    console.log(selectedIntegration)
    const [query, setQuery] = useState({});
    const [param, setParam] = useState({});
    const [headers, setHeaders] = useState({});
    const [body, setBody] = useState('');
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [requestInfo, setRequestInfo] = useState({}); // Estado para armazenar informações sobre a requisição

    const handleChangeQuery = (e) => {
        const { name, value } = e.target;
        setQuery(prevState => ({ ...prevState, [name]: value }));
    };

    const handleChangeParam = (e) => {
        const { name, value } = e.target;
        setParam(prevState => ({ ...prevState, [name]: value }));
    };

    const handleChangeHeaders = (e) => {
        const { name, value } = e.target;
        setHeaders(prevState => ({ ...prevState, [name]: value }));
    };

    const handleChangeBody = (e) => {
        setBody(e.target.value);
    };

    const handleRequest = async () => {
        setLoading(true);

        try {
            let url = selectedIntegration?.urlBase + selectedRouter?.request?.path;

            for (const [key, value] of Object.entries(param)) {
                url = url.replace(`{${key}}`, value);
            }

            const startTime = new Date(); // Captura o horário de início da requisição

            const options = {
                method: selectedRouter?.request?.method,
                headers: {
                    ...headers,
                    'Authorization': `Bearer ${selectedIntegration?.accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: body ? JSON.stringify(body) : undefined
            };

            const response = await fetch(url, options);

            const data = await response.json();

            const endTime = new Date(); // Captura o horário de término da requisição
            const duration = endTime - startTime; // Calcula a duração da requisição

            setResponse({
                status: response.status,
                headers: response.headers,
                data
            });

            setRequestInfo({ url, status: response.status, duration }); // Armazena informações sobre a requisição
            setError(null);
        } catch (error) {
            setError(error.message);
            setResponse(null);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='app-request'>
            <div className='col vertical padding-x2 gap2'>
                {selectedRouter.request?.route && Object.keys(selectedRouter.request?.query || {}).length > 0 && (
                    <div className='item-param vertical padding-x2'>
                        <h5>Query Parameters:</h5>
                        {renderInputs(selectedRouter.request?.query, handleChangeQuery)}
                    </div>
                )}

                {selectedRouter.request && Object.keys(selectedRouter.request?.param || {}).length > 0 && (
                    <div className='item-param vertical padding-x2'>
                        <h5>Path Parameters:</h5>
                        {renderInputs(selectedRouter.request?.param, handleChangeParam)}
                    </div>
                )}

                {selectedRouter.request && Object.keys(selectedRouter.request?.headers || {}).length > 0 && (
                    <div className='item-param vertical padding-x2'>
                        <h5>Headers:</h5>
                        {renderInputs(selectedRouter.request.headers, handleChangeHeaders)}
                    </div>
                )}

                {body && <div className='item-param vertical padding-x2'>
                    <h5>Body:</h5>
                    <textarea
                        value={body}
                        onChange={handleChangeBody}
                        placeholder="Request Body"
                    />
                </div>}

                <button onClick={() => handleRequest()} disabled={loading}>Enviar Requisição</button>
                {loading && <div className="loading-bar" />}

                {/* Exibe informações sobre a requisição */}
                {requestInfo && (
                    <div className="request-info">
                        <h5>Informações da Requisição:</h5>
                        <p>URL: {requestInfo.url}</p>
                        <p>Status: {requestInfo.status}</p>
                        <p>Tempo de resposta: {requestInfo.duration}ms</p>
                    </div>
                )}
            </div>

            <div className='col vertical'>
                {response && (
                    <div>
                        <JsonView value={response.data} />
                        <h5>Headers:</h5>
                        <JsonView value={response.headers} />
                    </div>
                )}
                {error && (
                    <div>
                        <h5>Error:</h5>
                        <p>{error}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const renderInputs = (params, onChange) => {
    return params && Object.entries(params).map(([key, value]) => (
        <div key={key} className='row vertical'>
            <h5>{key}:</h5>
            <input
                type="text"
                name={key}
                placeholder={key}
                value={value ?? value}
                onChange={onChange}
            />
        </div>
    ));
};
