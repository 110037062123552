import { BsBag, BsBagFill, BsCardHeading, BsCartCheck, BsCartCheckFill, BsChatSquareDots, BsChatSquareDotsFill, BsChatSquareText, BsChatSquareTextFill, BsCheckCircle, BsCheckCircleFill, BsClockHistory, BsCreditCard2Front, BsCreditCard2FrontFill, BsMessenger } from 'react-icons/bs';
import './CardDetailModal.css';
import { useState } from 'react';
import { CardDetailModalMessage } from './CardDetailModalMessager';
import { CardDetailModalProdutos } from './CardDetailModalProdutos';
import { CardDetailModelIntegration } from './CardDetailModelIntegration';
import Mapa from '../../Components/Testes/Mapa';
export const CardDetailMoral = ({ Card, onClose }) => {
console.log(Card)
    const [selectedOption, setSelectedOption] = useState('Integração');

    const coordenada = {
        nome: Card?.storeName,
        coordenadas: Card?.deliveryAddress
    };

    return (
        <div className="app-card-detail-modal">
            <div className='container'>
                <div className='row'>
                    <div className='header-content'>
                        <div className='close-button' onClick={() => onClose(false)} />
                    </div>
                </div>
                <div className='row center-j'>
                    <div className='profile-content'>
                        <div className='photo-content' style={{ backgroundColor: Card?.defaultColor }} />
                        <div className='info-content'>
                            <span>{Card.platform}</span>
                            <span>@{Card.storeName}</span>
                        </div>
                    </div>
                </div>
                <div className='row center-j'>
                    <div className='content-options-menu'>
                        <span className={`item-option ${selectedOption === 'Integração' ? 'selected' : ''}`} onClick={() => setSelectedOption('Integração')}>
                            {selectedOption !== 'Integração' ? <BsCheckCircle size={14} /> : <BsCheckCircleFill size={14} />}
                        </span>
                        <span className={`item-option ${selectedOption === 'Pedido' ? 'selected' : ''}`} onClick={() => setSelectedOption('Pedido')}>
                            {selectedOption !== 'Pedido' ? <BsCartCheck size={15} /> : <BsCartCheckFill size={15} />}
                        </span>
                        <span className={`item-option ${selectedOption === 'Conversa' ? 'selected' : ''}`} onClick={() => setSelectedOption('Conversa')}>
                            {selectedOption !== 'Conversa' ? <BsChatSquareDots size={13} /> : <BsChatSquareDotsFill size={13} />}
                        </span>
                    </div>
                </div>
                
                <div className='row'>
                    <div className='title-detail-selected'>
                        {selectedOption}
                    </div>
                </div>

                <div className='separator' />
                {selectedOption === 'Integração' && (<CardDetailModelIntegration Card={Card} />)}
                {selectedOption === 'Conversa' && (<CardDetailModalMessage Card={Card} />)}
                {selectedOption === 'Pedido' && (<CardDetailModalProdutos Card={Card} />)}
                {/* <Mapa coordenadas={[coordenada]}/> */}
            </div>
        </div>
    )
}