import './CardDetailModalProdutos.css'
export const CardDetailModalProdutos = ({ Card }) => {
    return (
        <div className="card-detail-products">
            <div className="products-content">
                <div className='item-detail-product'>
                    <div>ID</div>
                    <div>PRODUTO</div>
                    <div>QTD</div>
                    <div>VU</div>
                    <div>VT</div>
                </div>
                {Card.items.map((item, index) => {
                    return (
                        <div key={index} className='item-detail-product'>
                            <div title={item.item}>
                                {item.item}
                            </div>
                            <div title={item.name}>
                                {item.name}
                            </div>
                            <div title={item.qt}>
                                {item.qt}
                            </div>
                            <div title={item.priceUn.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}>
                                {item.priceUn}
                            </div>
                            <div title={item.priceTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}>
                                {item.priceTotal}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='totalize-shipping'>
                <div>
                    <span>Total</span>
                    <span>{Card.totalPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div>
                    <span>Desconto</span>
                    <span>{Card.totalDiscount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div>
                    <span>Sub-total</span>
                    <span>{Card.totalPriceFinish.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                </div>
            </div>
        </div>
    )
}