import React from 'react';
import './HomePage.css';
import userAvatar from '../../assets/Hubbit.png';
import icon from '../../assets/ico.svg';

const HomePage = () => {
  return (
    <div className="user-container">
      <header className="user-header">
        <a href="#" className="logo">
          <span className="sr-only"><img src={icon} alt="Logo" /></span>
        </a>
        <div className="user-info">
          <img src={userAvatar} alt="User Avatar" className="user-avatar" />
          <span className="user-name">Olá, [Nome do Usuário]</span>
        </div>
        <nav className="user-nav">
          <a href="#" className="user-nav-link">Dashboard</a>
          <a href="#" className="user-nav-link">Integrações</a>
          <a href="#" className="user-nav-link">Notificações</a>
          <a href="#" className="user-nav-link">Configurações</a>
        </nav>
      </header>
      <main className="user-main">
        <section className="overview">
          <h1>Bem-vindo de volta, [Nome do Usuário]</h1>
          <p>Aqui está um resumo das suas atividades recentes e integrações ativas.</p>
          <div className="overview-grid">
            <div className="overview-item">
              <h3>Integrações Ativas</h3>
              <p>Você tem <strong>5 integrações</strong> ativas.</p>
            </div>
            <div className="overview-item">
              <h3>Fluxos de Trabalho</h3>
              <p><strong>3 fluxos</strong> estão em execução atualmente.</p>
            </div>
            <div className="overview-item">
              <h3>Notificações</h3>
              <p><strong>2 novas notificações</strong> desde sua última visita.</p>
            </div>
          </div>
        </section>
        <section className="quick-access">
          <h2>Acessos Rápidos</h2>
          <div className="quick-access-grid">
            <a href="#" className="quick-access-item">Gerenciar Integrações</a>
            <a href="#" className="quick-access-item">Criar Novo Fluxo</a>
            <a href="#" className="quick-access-item">Ver Relatórios</a>
            <a href="#" className="quick-access-item">Configurar Conta</a>
          </div>
        </section>
        <section className="recent-activities">
          <h2>Atividades Recentes</h2>
          <ul className="activity-list">
            <li className="activity-item">Você integrou o sistema CRM XYZ em 14/08/2024.</li>
            <li className="activity-item">Fluxo de trabalho "Envio de relatórios" foi executado com sucesso em 12/08/2024.</li>
            <li className="activity-item">Atualização de integração com ERP ABC realizada em 10/08/2024.</li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default HomePage;
