import { BsDiamond } from "react-icons/bs";
import './Products.css';
import { Table } from "../../Components/Tables/Table";
// import { Table } from "../../Components/Tables/Table.js.old";

export const Products = () => {

    return (
        <div className="app-products">
            <div className='header row vert pad-x3 gap-x2'>
                <div className='row hori'>
                    <h1><BsDiamond fill='var(--theme-color)' /> Products</h1>
                </div>
                <div className='row hori'>
                    <h5><BsDiamond size={10} /> Platforms / <BsDiamond size={10} /> Configurations </h5>
                </div>
            </div>

            <div className="">
                <span data-tooltip="Tooltip help here!" data-flow="top">CSS Tooltips</span>
                teste
            </div>

            <div className="row vert hidden pad-x3">
                <Table query={`
                SELECT 
                --B.URL_CAMINHO + B.[TOKEN ]     AS [url]
                PRODUTO                        AS [Produto]
               ,DESCRICAO                      AS [Descrição]
               ,UNIDADE_MEDIDA                 AS [Un. Medida]
             FROM PRODUTOS         A WITH(NOLOCK)
             LEFT
             JOIN DL_IMAGENS       B WITH(NOLOCK)ON B.ProdutoId = A.PRODUTO`} primaryKey={'Produto'} pageSize={50} />
            </div>


            <div className="">
                teste
            </div>
        </div>
    );
};
