import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/root.css'
import './assets/styles/default-components.css';
import './assets/styles/params-components.css';
import './assets/styles/custom-components.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './Pages/Home/Home';
import { Store } from './Pages/Store/Store';
import { Streaming } from './Pages/Liveship/Streaming';
import { Settings } from './Pages/Settings/Settings';
import { Ifood } from './Pages/Integrations/Platforms/Ifood';
import { Integrations } from './Pages/Integrations/Integrations';
import { WhatsappHub } from './Pages/whatsapp/WhatsappHub';
import { Products } from './Pages/Products/Products';
import { useState } from 'react';
import { IntegrationsDetails } from './Pages/Integrations/IntegrationsDetails';
import LandingPage from './Pages/LandPage/LandingPage';
import HomePage from './Pages/Home/HomePage';

const root = ReactDOM.createRoot(document.getElementById('root'));

const AppWithRedirect = () => {
  const [isLogged, setIsLogged] = useState(false);

  const handleLogged = (value) => {
    setIsLogged(value)
  }

  if (isLogged) {
    return (
      <App />
    )
  }else{    
    return (
      <LandingPage handleLogged={handleLogged} />
    )
  }
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<AppWithRedirect />}>
        <Route path='/home' element={<HomePage />} />
        <Route path='/store' element={<Store />} />
        <Route path='/liveship' element={<Streaming />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/integrations' element={<Integrations />} />
        <Route path='/integrations/:platform' element={<IntegrationsDetails/>} />
        <Route path='/ifood' element={<Ifood />} />
        <Route path='/whatsapp' element={<WhatsappHub />} />
        <Route path='/products' element={<Products />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
