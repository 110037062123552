import { useState } from 'react';
import { BsActivity, BsArrowDown, BsBug, BsChevronDown, BsChevronUp, BsDiamond, BsFillSendPlusFill, BsFlower1, BsGitlab, BsIntersect, BsKanban, BsList, BsSendPlusFill, BsTable } from 'react-icons/bs';
import './Ifood.css';
import { Requisicao } from './Requisicao/Requisicao';
import { Fluxo } from './Fluxo/Fluxo';
import { Integracao } from './Integracao/Integracao';
import { useLocation } from 'react-router-dom';

// Componente Menu
const Menu = ({ options }) => {
    return (
        <ul>
            {options.map((option, index) => (
                <li key={index} className="menu-item">{option}</li>
            ))}
        </ul>
    );
};

// Componente Expander
const Expander = ({ title, children }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="expander">
            <div className="expander-header" onClick={() => setExpanded(!expanded)}>
                {!expanded ? <BsChevronDown /> : <BsChevronUp fill="#4590ed" />}
                <h4>{title}</h4>
            </div>
            <div className={`expander-content ${expanded ? 'expanded' : ''}`}>
                <div className='content-children'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export const Ifood = ({integration, handleSelectedIntegration, options}) => {
    const [selectedIntegration, setSelectedIntegration] = useState({});
    const [selectedRouter, setSelectedRouter] = useState({});
    const [selectedModeViewer, setSelectedModeViewer] = useState('requisicao');

    return (
        <div className="app-ifood">

            <div className='header row vert pad-x3 gap-x2'>
                <div className='row hori'>
                    <h1><BsDiamond fill='var(--theme-color)' /> {integration?.platform}</h1>
                </div>
                <div className='row hori'>
                    <h5 className='row hori gap-x1 center-a'>
                        <BsDiamond size={10} /> 
                        <div onClick={() => handleSelectedIntegration(null)}> Integration</div> / <BsDiamond size={10} /> Serviços </h5>
                </div>
            </div>

            <div className="integration-container">
                <div className="columns-map">
                    {options?.documentation?.map((expander, index) => (
                        <Expander key={index} title={expander.title}>
                            <ul>
                                {expander.options.map((option, index) => (
                                    <li key={index} className="menu-item" onClick={() => {setSelectedRouter(option); setSelectedIntegration(expander)}}>{option.title}</li>
                                ))}
                            </ul>
                        </Expander>
                    ))}
                </div>

                <div className='tools-integration'>
                    <div className='types-viewer'>
                        <span className={`radio-button-model1 ${selectedModeViewer === 'requisicao' ? 'selected' : ''} `} onClick={() => setSelectedModeViewer('requisicao')}><BsBug size={11} /> Requisição</span>
                        <span className={`radio-button-model1 ${selectedModeViewer === 'fluxo' ? 'selected' : ''} `} onClick={() => setSelectedModeViewer('fluxo')}><BsFlower1 size={11} /> Fluxo</span>
                        <span className={`radio-button-model1 ${selectedModeViewer === 'integracao' ? 'selected' : ''} `} onClick={() => setSelectedModeViewer('integracao')}><BsIntersect size={11} /> Integração</span>
                    </div>

                    <div className='tools-children'>
                        {selectedModeViewer === 'requisicao' && <Requisicao selectedRouter={selectedRouter} selectedIntegration={selectedIntegration}/>}
                        {selectedModeViewer === 'fluxo' && <Fluxo />}
                        {selectedModeViewer === 'integracao' && <Integracao />}
                    </div>
                </div>
            </div>
        </div>
    );
};



