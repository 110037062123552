import { BsBug, BsDiamond, BsDiamondFill, BsFlower1, BsIntersect, BsPlay, BsPlayFill } from "react-icons/bs";
import './IntegrationsDetails.css';
import { useEffect, useState } from "react";
import { singleSQLRequest } from "../../Services/Api/GenericRequest";
import { CiEdit } from "react-icons/ci";
import { HiMiniSignal } from "react-icons/hi2";
import { GrSchedulePlay } from "react-icons/gr";
import { Link, useLocation, useParams } from "react-router-dom";
import { MdOutlineSchedule } from "react-icons/md";
import { ExpanderDefault } from "../../Components/expander/ExpanderDefault";
import { getDaysOfTheMonth, getDaysOfTheWeek, getIntervalCron, isValidExpression } from "../../Functions/Cron";
import cronParser from 'cron-parser';
import moment from "moment";
import { CardStepHttpItem } from "./Components/CardStepHttpItem";
import { CardStepTsqlItem } from "./Components/CardStepTsqlItem";
import { CardStepJavascriptItem } from "./Components/CardStepJavascriptItem";
import { StepHttpModal } from "./Pages/StepHttpModal";
import { StepTsqlModal } from "./Pages/StepTsqlModal";
import { StepJavascriptModal } from "./Pages/StepJavascriptModal";

export const IntegrationsDetails = () => {
    const location = useLocation();
    const { routineName } = useParams();
    const [service, setService] = useState(location?.state?.service);
    const [selectedTabDetail, setSelectedTabDetail] = useState('Principal');
    const [routines, setRoutines] = useState([]);
    const [selectedRoutine, setSelectedRoutine] = useState({});
    const [ativo, setAtivo] = useState(false);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [routes, setRoutes] = useState([]);
    const [command, setCommand] = useState(selectedRoutine?.command);


    const [schedulingType, setSchedulingType] = useState('');
    const [cronExpression, setCronExpression] = useState(selectedRoutine?.cron_expression || '* * * * *');
    const [selectedTypeInterval, setSelectedTypeInterval] = useState('');
    const [typeInterval, setTypeInterval] = useState([
        { key: "minuto", value: "Minuto" },
        { key: "hora", value: "Hora" },
        { key: "dia", value: "Dia" },
        { key: "semana", value: "Semana" },
        { key: "mes", value: "Mes" },
    ]);
    const daysMonth = Array.from({ length: 31 }, (_, i) => i + 1);
    const [intervalCron, setIntervalCron] = useState({});
    const [daysOfTheWeekCron, setDaysOfTheWeekCron] = useState({});
    const [daysOfTheMonthCron, setDaysOfTheMonthCron] = useState({});
    const [interval, setInterval] = useState();
    const [intervalType, setIntervalType] = useState();
    const [minuteCron, setMinuteCron] = useState('*');
    const [minute, setMinute] = useState('*');
    const [hourCron, setHourCron] = useState('*');
    const [hour, setHour] = useState('*');
    const [dayCron, setDayCron] = useState('*');
    const [day, setDay] = useState('*');
    const [monthCron, setMonthCron] = useState('*');
    const [month, setMonth] = useState('*');
    const [week, setWeek] = useState('*');
    const [weekCron, setWeekCron] = useState('*');

    const [selectedDaysOfMonth, setSelectedDaysOfMonth] = useState([]);
    const [steps, setSteps] = useState();

    const [showModal, setShowModal] = useState(false);
    const [selectedStep, setSelectedStep] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const response = await singleSQLRequest(`EXEC API_SERVICES_ROUTINES ${service.service}`);
            setRoutines(JSON.parse(Object.values(response[0])[0]));
        }

        fetchData();
    }, [service.service]);

    useEffect(() => {
        setSteps(routines?.routines?.find(item => item?.name === selectedRoutine?.name)?.steps);
    }, [routines, selectedRoutine]);

    useEffect(() => {
        if (routines.routines) {
            const routine = routines.routines.find(r => r.name === routineName);
            setSelectedRoutine(routine);
        }
    }, [routines, routineName]);

    useEffect(() => {
        if (selectedRoutine) {

            try {

                setAtivo(selectedRoutine?.status);
                setNome(selectedRoutine?.name);
                setDescricao(selectedRoutine?.description);
                setCommand(selectedRoutine.command);

                setCronExpression(selectedRoutine.cron_expression);
                setSchedulingType(selectedRoutine?.scheduling_type);

                setIntervalCron(getIntervalCron(selectedRoutine.cron_expression));
                setDaysOfTheWeekCron(getDaysOfTheWeek(selectedRoutine.cron_expression));
                setDaysOfTheMonthCron(getDaysOfTheMonth(selectedRoutine.cron_expression));

                const cron = cronParser.parseExpression(selectedRoutine?.cron_expression);
                const { minute, hour, dayOfWeek, dayOfMonth } = cron.fields;
                handleMinute(minute.length === 60 ? 1 : minute.length === 1 ? minute[0] : 60 / minute.length);
                handleHour(hour.length === 24 ? 1 : hour.length === 1 ? hour[0] : 24 / hour.length);
                handleDayOfMonth(dayOfMonth.length === 31 ? 1 : dayOfMonth.length === 1 ? dayOfMonth[0] : 31 / dayOfMonth.length);
                handleDaysOfWeek(dayOfWeek.length === 8 ? 1 : dayOfWeek.length === 1 ? dayOfWeek[0] : 8 / dayOfWeek.length);
                setSelectedDaysOfMonth(dayOfMonth); // set initial selected days

            } catch (err) {
                console.log(err)

            }

        }
    }, [selectedRoutine]);

    const handleExpanderClick = (type) => {
        setSchedulingType(type);
        resetCronFields(type);
    };

    const resetCronFields = (type) => {
        setMinute('*');
        setHour('*');
        setDay('*');
        setMonth('*');
        setWeek('*');

        if (type === 'interval') {
            setInterval('');
            setSelectedTypeInterval('');
        } else if (type === 'byHour') {
            setMinute('');
            setHour('');
        } else if (type === 'byMonth') {
            setMinute('');
            setHour('');
            setDay('');
        }
    };

    const handleInterval = (value) => {
        setInterval(value);

        const handle = (func, funcCron, tt) => {
            tt = Number(tt);
            if (isNaN(tt)) {
                console.error("O valor de intervalo não é um número válido");
                return;
            }

            let expression = tt > 1 ? `*/${tt}` : '*';
            funcCron(expression);
            func(tt);
        }

        if (selectedTypeInterval === 'minuto') {
            handle(setMinute, setMinuteCron, value);
        } else if (selectedTypeInterval === 'hora') {
            handle(setHour, setHourCron, value);
        } else if (selectedTypeInterval === 'dia') {
            handle(setDay, setDayCron, value);
        } else if (selectedTypeInterval === 'semana') {
            handle(setWeek, setWeekCron, value);
        } else if (selectedTypeInterval === 'mes') {
            // handleMonth(value);
        }
    };

    const handleMinute = (mm) => {
        mm = Number(mm);
        if (isNaN(mm)) {
            console.error("O valor de minuto não é um número válido");
            return;
        }

        let expression = mm > 1 ? `*/${mm}` : '*';
        handleInterval(mm);
        setMinuteCron(expression);
        setMinute(mm);
    };

    const handleHour = (hh) => {
        hh = Number(hh);
        if (isNaN(hh)) {
            console.error("O valor de hora não é um número válido");
            return;
        }

        let expression = hh > 1 ? `*/${hh}` : '*';
        handleInterval(hh);
        setHourCron(expression);
        setHour(hh);
    };

    const handleDayOfMonth = (dd) => {
        dd = Number(dd);
        if (isNaN(dd)) {
            console.error("O valor de dia do mês não é um número válido");
            return;
        }

        let expression = dd > 1 ? `*/${dd}` : '*';
        handleInterval(dd);
        setDayCron(expression);
        setDay(dd);
    };

    const handleDaysOfWeek = (dd) => {
        dd = Number(dd);
        if (isNaN(dd)) {
            console.error("O valor de dia da semana não é um número válido");
            return;
        }

        let expression = dd > 1 ? `*/${dd}` : '*';
        handleInterval(dd);
        setWeekCron(expression);
        setWeek(dd);
    };

    const handleDayClick = (day) => {
        let updatedDays = [...selectedDaysOfMonth];
        if (updatedDays.includes(day)) {
            updatedDays = updatedDays.filter(d => d !== day);
        } else {
            updatedDays.push(day);
        }
        setSelectedDaysOfMonth(updatedDays);
        setDay(updatedDays.length > 0 ? updatedDays.join(',') : '*');
    };

    const handleCronExpression = (value) => {
        isValidExpression(value);
        setCronExpression(value);
    }

    const handleCloseModal = () => {
        setShowModal(!showModal)
    }

    const handleSelectedStep = (stp) => {
        setShowModal(false)
        setSelectedStep(stp)
    }


    return (
        <div className="app-integrations-details">
            <div className="content">

                <div className='header row vert pad-x3 gap-x2'>
                    <div className='row hori'>
                        <h1><BsDiamond fill='var(--header-icon-color)' /> {service?.name}</h1>
                    </div>
                    <div className='row hori'>
                        <h5>
                            <Link to={'/'}><BsDiamond size={10} /> Home / </Link>
                            <Link to={'/integrations'}><BsDiamond size={10} /> HubStore / </Link>
                            <Link to={'/integrations'}><BsDiamond size={10} /> Integrações / </Link>
                            <Link to={'/integrations'}><BsDiamondFill size={10} /> Plataformas</Link>
                        </h5>
                    </div>
                </div>

                <div className='tools'>

                </div>

                <div className="row hori hidden" style={{ display: 'grid', gridTemplateColumns: '320px 1fr' }}>

                    <div className="vert hidden" style={{ backgroundColor: '#fff' }} >
                        <div className="vert pad-x3 gap-x1" style={{ color: '#666' }}>
                            <h2>Rotinas</h2>
                            <input type="search" placeholder="Pesquisar rotinas..." style={{ width: '100%', backgroundColor: '#fff' }} />
                        </div>
                        <div className="scrool-y" >
                            <div className="row hori gap-x2 pad-x3" style={{ display: 'flex', flexDirection: 'column' }}>
                                {routines.routines && routines.routines.map((rt, index) => {

                                    const isSelected = selectedRoutine?.name === rt.name;
                                    const hasErrorStatus = rt?.last_run_status === 500;

                                    return (
                                        <div key={index} className={`card-routine ${isSelected ? 'selected' : ''}`} onClick={() => setSelectedRoutine(rt)}>
                                            <div className="content-card">
                                                <div className="flex-1 hidden">
                                                    <h4>
                                                        {rt.name}
                                                    </h4>
                                                    <h5>
                                                        {rt.description}
                                                    </h5>
                                                </div>
                                                <div className="flex-0 center-j center-a">
                                                    {hasErrorStatus && (
                                                        <span className={`tag-status ${hasErrorStatus ? 'failed' : ''}`}>
                                                            {hasErrorStatus ? 'Falha' : ''}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            {isSelected && (
                                                <div className="hori center-a">
                                                    <BsPlayFill fill="limegreen" size={22} />
                                                    <CiEdit size={18} />
                                                    <HiMiniSignal size={18} />
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row vert pad-x5 gap-x2 hidden" style={{ display: 'grid', gridTemplateRows: 'auto auto 1fr', height:'100hv', backgroundColor: '#fbfbfb' }}>

                        <div className="hori gap-x3 flex pad-b4" style={{height: '70px', overflow: 'hidden'}}>
                            <div className="vert flex-1 gap-x1">
                                <h4 style={{ fontWeight: '600', fontSize: '1rem' }}>
                                    {nome}
                                </h4>
                                <h5 style={{ fontWeight: 'normal', color: 'rgb(137, 137, 137)' }}>
                                    {descricao}
                                </h5>
                            </div>
                            <div className="flex-1">
                                <div>
                                    <span className="hori center-a gap-x2"
                                        style={{ fontSize: 12 }}
                                        onClick={(e) => {
                                            return false
                                        }}>
                                        <div className={`point-status ${selectedRoutine?.last_completion_status === 200 ? 'sucess' : 'failed'}`} />
                                        {selectedRoutine?.last_run_status === 200 ? 'Concluído' : ''}
                                        {selectedRoutine?.last_run_status === 500 ? 'Falha' : ''}
                                    </span>
                                    <h5>
                                        {selectedRoutine?.currentTime}
                                    </h5>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div>
                                    <h5>
                                        <GrSchedulePlay /> {moment(selectedRoutine?.last_run).format('DD/MM/yyyy - HH:mm')}
                                    </h5>
                                    <h5>
                                        <MdOutlineSchedule /> {moment(selectedRoutine?.last_run).format('HH:mm')} - {moment(selectedRoutine?.last_finish).format('HH:mm')}
                                    </h5>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div>
                                    <h5>
                                        {selectedRoutine?.last_message}
                                    </h5>

                                </div>
                            </div>
                        </div>

                        <div className="hori gap-x2">
                            <button className={`radio-button-model1 ${selectedTabDetail === 'Principal' ? 'selected' : ''}`} onClick={() => setSelectedTabDetail('Principal')}>Principal</button>
                            <button className={`radio-button-model1 ${selectedTabDetail === 'Execução' ? 'selected' : ''}`} onClick={() => setSelectedTabDetail('Execução')}>Execução</button>
                            <button className={`radio-button-model1 ${selectedTabDetail === 'Configurações' ? 'selected' : ''}`} onClick={() => setSelectedTabDetail('Configurações')}>Configurações</button>
                        </div>
                        <div className="vert pad-x" style={{ backgroundColor: 'transparent', border: '1px solid transparent', borderRadius: '0.3rem'}}>
                            {selectedTabDetail === 'Execução' && (
                                <div className="vert gap-x3">
                                    <div>
                                        <h3 style={{ fontWeight: 600, fontSize: 15 }}>Comunicação</h3>
                                        <div>
                                            <label className='row vert' style={{ fontSize: '0.7rem', color: 'gray' }}>
                                                Rota:
                                                <select>
                                                    <options>Selecionar</options>
                                                </select>
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 style={{ fontWeight: 600, fontSize: 15 }}>Agendamento</h3>
                                        <div>
                                            <ExpanderDefault
                                                header={<div>Intervalo</div>}
                                                showRadios={true}
                                                showIcon={false}
                                                expanded={schedulingType === 'interval'}
                                                onClick={() => handleExpanderClick('interval')}>
                                                <div className="hori flex gap-x3 pad-l2 pad-r2 pad-b2 align-center">
                                                    <div style={{ fontSize: '0.8rem' }}>
                                                        À cada:
                                                    </div>
                                                    <input className="flex1" type="number" placeholder="" value={interval} onChange={(e) => handleInterval(e.target.value)} />
                                                    <div style={{ width: '100px' }}>
                                                        <select>
                                                            <option value="minutes">Minutos</option>
                                                            <option value="hours">Horas</option>
                                                            <option value="days">Dias</option>

                                                        </select>
                                                        {/* <Select data={typeInterval} selectedKey={selectedTypeInterval} onSelectionChange={handleSelectTypeInterval} multiSelect={false} /> */}
                                                    </div>
                                                </div>
                                            </ExpanderDefault>
                                            <ExpanderDefault
                                                header={<div>Todos os dias</div>}
                                                showRadios={true}
                                                showIcon={false}
                                                expanded={schedulingType === 'byHour'}
                                                onClick={() => handleExpanderClick('byHour')}>
                                                <div className={`vert gap-x3 pad-l2 pad-r2 pad-b2`}>
                                                    <div className={`hori gap-x2 align-center`}>
                                                        <div style={{ fontSize: '0.8rem' }}>Às:</div>
                                                        <div className={`hori gap-x2`}>
                                                            <input style={{ maxWidth: '80px' }} type='number' placeholder='Hora' value={hour} onChange={(e) => handleHour(e.target.value)} />
                                                            : <input style={{ maxWidth: '80px' }} type='number' placeholder='Minuto' value={minute} onChange={(e) => handleMinute(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </ExpanderDefault>
                                            <ExpanderDefault
                                                header={<div>Dias do Mês</div>}
                                                showRadios={true}
                                                showIcon={false}
                                                expanded={schedulingType === 'byMonth'}
                                                onClick={() => handleExpanderClick('byMonth')}>
                                                <div className={`vert gap-x3 pad-l2 pad-r2 pad-b2`}>
                                                    <div className={`vert gap-x1`}>
                                                        <div className='days-container'>
                                                            {daysMonth.map((day) => (
                                                                <span
                                                                    key={day}
                                                                    className={`day ${selectedDaysOfMonth.includes(day) ? 'scheduled' : ''}`}
                                                                    onClick={() => handleDayClick(day)}>
                                                                    {day}
                                                                </span>
                                                            ))}
                                                        </div>
                                                        <div className={`vert`} style={{ fontSize: '0.7rem' }}>
                                                            <div>
                                                                <strong>Selecionados: </strong>
                                                                {selectedDaysOfMonth.length === 31 ? 'Todos os dias' : selectedDaysOfMonth.join(', ')}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={`hori gap-x2 align-center`}>
                                                        <div style={{ fontSize: '0.8rem' }}>Às:</div>
                                                        <div className={`hori gap-x2`}>
                                                            <input style={{ maxWidth: '80px' }} type='number' placeholder='Hora' value={hour} onChange={(e) => handleHour(e.target.value)} />
                                                            : <input style={{ maxWidth: '80px' }} type='number' placeholder='Minuto' value={minute} onChange={(e) => handleMinute(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </ExpanderDefault>
                                            <ExpanderDefault
                                                header={<div>Livre</div>}
                                                showRadios={true}
                                                showIcon={false}
                                                expanded={schedulingType === 'expression'}
                                                onClick={() => handleExpanderClick('expression')}>
                                                <div className={`vert gap-x3 pad-l2 pad-r2 pad-b2`}>
                                                    <div className={`hori gap-x2`}>
                                                        <div>Comando:</div>
                                                        <input type='text' placeholder='* * * * *' value={cronExpression} onChange={(e) => handleCronExpression(e.target.value)} />
                                                    </div>
                                                </div>
                                            </ExpanderDefault>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {selectedTabDetail === 'Principal' && (
                    
                                <div className="vert">
                                    <div className="pad-b3">
                                        <h3 style={{ fontWeight: 600, fontSize: 15 }}>Etapas</h3>
                                    </div>
                                    <div className="scrool-y hidden gap-x3" style={{height: 'calc(100vh - 350px)'}}>

                                        <div className="vert gap-x3 pad-x1">
                                            {steps && steps.map((step, index) => {

                                                return(
                                                    <div className={`card-step-item shadow ${selectedStep.step === step.step ? 'selected' : ''}`} onClick={() => handleSelectedStep(step)}>                                            
                                                        {step.code_type === 1 && (
                                                            <CardStepHttpItem step={step} />
                                                        )}
                                                        {step.code_type === 2 && (
                                                            <CardStepTsqlItem step={step} />
                                                        )}   
                                                        {step.code_type === 3 && (
                                                            <CardStepJavascriptItem step={step}/>
                                                        )}  
                                                        {step.code_type === 4 && (
                                                            <>{step.name}</>
                                                        )}      
                                                        {step.code_type === 5 && (
                                                            <>{step.name}</>
                                                        )}                                       
                                                    </div>
                                                )
                                                
                                            
                                            
                                            })}
                                        </div>
                                    </div>
                                    <div className="hori pad-t3 gap-x2">
                                        <button type="new">Novo</button>
                                        {selectedStep && (<button type="edit" onClick={() => setShowModal(true)}>Editar</button>)}
                                        {selectedStep && (<button type="delete">Excluir</button>)}
                                    </div>
                                </div>
                            )}
                        </div>
                        {showModal && selectedStep?.code_type === 1 && (
                            <StepHttpModal step={selectedStep} onClose={handleCloseModal}/>
                        )}
                        {showModal && selectedStep?.code_type === 2 && (
                            <StepTsqlModal step={selectedStep} onClose={handleCloseModal}/>
                        )}
                        {showModal && selectedStep?.code_type === 3 && (
                            <StepJavascriptModal step={selectedStep} onClose={handleCloseModal}/>
                        )}
                        {/* {selectedRoutine && <IntegrationsStep selectedRoutine={selectedRoutine} />}                         */}
                    </div>
                </div>
            </div>
        </div>
    )
}
