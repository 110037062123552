
import { Outlet } from 'react-router-dom';
import { Header } from './Layout/Header';

import './App.css';
import { SidebarMaster } from './Components/Sidebars/SidebarMaster';
function App() {
  return (
    <main className='app'>
      <SidebarMaster/>
      {/* <aside className='aside-container'></aside> */}
      {/* <header className='header-container'><Header/></header> */}

      <article className='article-container'><Outlet/></article>

      {/* <footer className='footer-container'></footer> */}
    </main>
  );
}

export default App;