import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

function StackedBarChart() {
  const data = [
    { plataforma: 'Ifood', produtividade: 100 },
    { plataforma: 'Rappi', produtividade: 80 },
    { plataforma: 'Farmacias App', produtividade: 70 },
    { plataforma: 'Drogaleste App', produtividade: 90 },
    // Adicione mais dados conforme necessário
  ];

  return (
    <BarChart width={400} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="plataforma" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="produtividade" fill="#8884d8" />
    </BarChart>
  );
}

export default StackedBarChart;
