import moment from "moment";
export const FormatToDay = (date) => {
    
    const data = date?.split('T')[0];
    try {
        const today = moment().startOf('day');
        const targetDate = moment(data);

        if (targetDate.isSame(today, 'd')) {
            return 'Hoje';
        } else if (targetDate.isSame(today.clone().subtract(1, 'day'), 'd')) {
            return 'Ontem';
        } else if (targetDate.isSame(today.clone().add(1, 'day'), 'd')) {
            return 'Amanhã';
        } else if (targetDate.isSame(today, 'week')) {
            return 'Essa semana';
        } else if (targetDate.isSame(today.clone().subtract(1, 'week'), 'week')) {
            return 'Semana passada';
        } else if (targetDate.isSame(today, 'month')) {
            return 'Este mês';
        } else {
            return date;
        }
    } catch (error) {
        return 'Antigo';
    }
}