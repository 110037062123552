import { BsDatabase } from "react-icons/bs";
import { Colors } from "../../../Functions/Colors";
import './CardStepHttpItem.css';
import { MdLanguage } from "react-icons/md";
export const CardStepHttpItem = ({ step }) => {

    const constants = step?.constants;
    const method = constants.find(ct => ct.content === 'method')?.value;
    const headersCount = constants?.filter(ct => ct.content === 'headers')?.length;
    const queryCount = constants?.filter(ct => ct.content === 'query')?.length;
    const pathsCount = constants?.filter(ct => ct.content === 'path')?.length;
    const bodyCount =  constants?.filter(ct => ct.content === 'body')?.length;

    const handleSelected = (selected) => {
        
    }

    return (
        <div key={step.step} className="hori gap-x4 pad-x3" onClick={() => handleSelected(step)} style={{display: 'grid', gridTemplateColumns: '45px 1fr'}}>
            
            <div className="vert center-a center-j">
                <div className="no-wrap">
                    <span style={{fontSize: '0.7rem', padding: '0.2rem 0.7rem', color: '#51b7e6', border: '1px solid #51b7e6', borderRadius: '1rem'}}>Request</span>
                </div>
            </div>
            <div className="vert"> 
                <div className="hori">
                {/* <MdLanguage/>   */}
                    <h3 style={{ fontWeight: 400, fontSize: 15 }}>{step.description}</h3>
                </div>
                <div className="vert">
                    <div className="hori flex gap-x2" style={{ fontSize: '0.8rem' }}>
                        <div className=" flex-0 center-a center-j" style={{ color: Colors.method[method.toUpperCase()], fontSize: '0.8rem',  fontWeight: '600' }}>
                            {method}
                        </div>
                        <div className="flex-1" style={{ color: '#959595' }}>
                            {constants.find(ct => ct.content === 'router')?.value}
                        </div>
                        <div className="hori flex-0">
                            <div className="hidden center-a center-j" style={{ fontSize: '0.6rem', width: '15px', height: '15px', backgroundColor: headersCount > 0 ? "blue" : 'transparent', borderRadius: '1rem', color: headersCount > 0 ? "#fff" : '' }}>
                                H
                            </div>
                            <div className="hidden center-a center-j" style={{ fontSize: '0.6rem', width: '15px', height: '15px', backgroundColor: queryCount > 0 ? "blue" : 'transparent', borderRadius: '1rem', color: queryCount > 0 ? "#fff" : '' }}>
                                Q
                            </div>
                            <div className="hidden center-a center-j" style={{ fontSize: '0.6rem', width: '15px', height: '15px', backgroundColor: pathsCount > 0 ? "blue" : 'transparent', borderRadius: '1rem', color: pathsCount > 0 ? "#fff" : '' }}>
                                P
                            </div>
                            <div className="hidden center-a center-j" style={{ fontSize: '0.6rem', width: '15px', height: '15px', backgroundColor: bodyCount > 0 ? "blue" : 'transparent', borderRadius: '1rem', color: bodyCount > 0 ? "#fff" : '' }}>
                                B
                            </div>
                        </div>
                    </div>
                </div>       
            </div>
        </div>
    )
}