class OrderModel {
    constructor(platform, defaultColor, OrderIdPlatform, dateTime, storeId, storeName, statusPlatform, statusLiveship, category, orderId, isExpress, type, isScheduling, schedulingStart, schedulingFinish, totalPrice, totalDiscount, totalPriceFinish, totalItems, items, payments, domainUrl, fullDetails) {
        this.platform = platform;
        this.defaultColor = defaultColor;
        this.OrderIdPlatform = OrderIdPlatform;
        this.dateTime = dateTime;
        this.storeId = storeId;
        this.storeName = storeName;
        this.statusPlatform = statusPlatform;
        this.statusLiveship = statusLiveship;
        this.category = category;
        this.orderId = orderId;
        this.isExpress = isExpress;
        this.type = type;
        this.isScheduling = isScheduling;
        this.schedulingStart = schedulingStart;
        this.schedulingFinish = schedulingFinish;
        this.totalPrice = totalPrice;
        this.totalDiscount = totalDiscount;
        this.totalPriceFinish = totalPriceFinish;
        this.totalItems = totalItems;
        this.items = items;
        this.payments = payments; 
        this.domainUrl = domainUrl;
        this.fullDetails = fullDetails;
    }
}

class Items {
    constructor(item, cod, name, qt, priceUn, priceTotal) {
        this.item = item;
        this.cod = cod;
        this.name = name;
        this.qt = qt;
        this.priceUn = priceUn;
        this.priceTotal = priceTotal;
    }
}

module.exports = { OrderModel, Items }