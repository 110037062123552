import React, { useState, useEffect } from 'react';
import './ExpanderDefault.css';
import { FiMinus, FiPlus } from 'react-icons/fi';

export const ExpanderDefault = ({ header, children, expanded = false, showRadios = false, onClick, showIcon = true }) => {
    const [isOpen, setIsOpen] = useState(expanded);

    useEffect(() => {
        setIsOpen(expanded);
    }, [expanded]);

    return (
        <div className="expander">
            <div className="expander-header no-select" onClick={onClick}>
                <div>
                    <h5 className='row hori gap-x2' style={{ opacity: isOpen ? '1' : '0.5' }}>
                        {showRadios && <input type='radio' checked={isOpen} readOnly />}
                        {header}
                    </h5>
                </div>
                <div onClick={() => setIsOpen(!isOpen)} style={{ opacity: isOpen ? '1' : '0.5' }}>
                   {showIcon && (isOpen ? <FiMinus /> : <FiPlus />)}
                </div> 
            </div>
            <div className={`expander-content ${isOpen ? 'expanded' : 'collapsed'}`}>
                {children}
            </div>
        </div>
    );
};
