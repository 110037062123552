import { useContext, useEffect, useState } from 'react'
import { UseSocketData } from './UseSocketData';

import './Streaming.css';

import capitalizeName from '../../Functions/CapitalizeName';
import { BsCalendar2Check, BsDiamond, BsDiamondFill, BsHash, BsHexagon, BsKanban, BsLightning, BsList, BsTable } from 'react-icons/bs';
import { Card } from './Cards/Card';
import { CardDetailMoral } from './CardDetailModal';
import Loader from '../../Components/Loaders/Loader';
import { Table } from '../../Components/Tables/Table';
import { Favicon } from '../../Functions/Favicon';

export const Streaming = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [filterValue, setFilterValue] = useState('');

    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedModeViewer, setSelectedModeViewer] = useState('kanban');
    const [visibleDetail, setVisibleDetail] = useState(false);

    const [platforms, setPlatforms] = useState([]);
    const [data, setData] = useState({});
    const [dataIntegrated, setDataIntegrated] = useState({});
    const [integrated, setIntegrated] = useState([]);
    const [novo, setNovo] = useState([]);
    const [recebido, setRecebido] = useState([]);
    const [entregando, setEntregando] = useState([]);
    const [cancelado, setCancelado] = useState([]);

    UseSocketData(setData, setDataIntegrated);

    useEffect(() => {

        const OrdersIntegrated = [];

        Object.keys(dataIntegrated).forEach(platform => {
            dataIntegrated[platform].forEach(ord => {
                OrdersIntegrated.push(ord);
            });
        });

        setIntegrated(OrdersIntegrated);

    }, [dataIntegrated])

    useEffect(() => {
        const newNovo = [];
        const newRecebido = [];
        const newEntregando = [];
        const newCancelado = [];

        // Concatenando todos os pedidos de todas as plataformas
        Object.keys(data).forEach(platform => {
            data[platform].forEach(ord => {
                switch (ord.category) {
                    case 'Novo':
                        newNovo.push(ord);
                        break;
                    case 'Recebido':
                        newRecebido.push(ord);
                        break;
                    case 'Entregando':
                        newEntregando.push(ord);
                        break;
                    case 'Cancelado':
                        newCancelado.push(ord);
                        break;
                    default:
                        break;
                }
            });
        });

        // Ordenando os pedidos por data e hora decrescente
        newNovo.sort((a, b) => new Date(a.schedulingStart) - new Date(b.schedulingStart));
        newRecebido.sort((a, b) => new Date(a.schedulingStart) - new Date(b.schedulingStart));
        newEntregando.sort((a, b) => new Date(a.schedulingStart) - new Date(b.schedulingStart));
        newCancelado.sort((a, b) => new Date(a.schedulingStart) - new Date(b.schedulingStart));

        // Atualizando os estados uma vez com todos os pedidos
        setNovo(newNovo);
        setRecebido(newRecebido);
        setEntregando(newEntregando);
        setCancelado(newCancelado);

        setPlatforms(capitalizeName(Object.keys(data)));

        console.log(data)

        if (Object?.keys(data).length > 0) {
            setIsLoading(false);
        }

        console.log(data)
    }, [data]);

    function compare(a, b) {
        const dateTimeA = new Date(a.schedulingStart);
        const dateTimeB = new Date(b.schedulingStart);

        if (isNaN(dateTimeA) || isNaN(dateTimeB)) {
            return 0;
        }

        return dateTimeA - dateTimeB;
    }

    const handleStyleCells = (index, row, cell) => {

        if (index === 0) {
            return Favicon(row['domainUrl'])
        } else if (index === 8) {
            return cell ? <BsLightning fill='blue' /> : ' ';
        } else if (index === 9) {
            return cell ? <BsCalendar2Check fill='orange' /> : ' ';
        }

    }

    return (
        <div className="app-streaming">

            <div className='header row vert pad-x3 gap-x2'>
                <div className='row hori'>
                    <h1><BsDiamond fill='var(--header-icon-color)' /> Liveship</h1>
                </div>
                <div className='row hori'>
                    <h5><BsDiamond size={10} /> Home / <BsDiamondFill size={10} /> Aplicativos </h5>
                </div>
            </div>

            <div className='tools'>

                <div className='types-viewer row hori pad-x2 gap-x2'>
                    <span className={`radio-button-model1 ${selectedModeViewer === 'kanban' ? 'selected' : ''} `} onClick={() => setSelectedModeViewer('kanban')}><h5><BsKanban size={11} /> Kanban</h5></span>
                    <span className={`radio-button-model1 ${selectedModeViewer === 'table' ? 'selected' : ''} `} onClick={() => setSelectedModeViewer('table')}><h5><BsTable size={11} /> Tabela</h5></span>
                    <span className={`radio-button-model1 ${selectedModeViewer === 'list' ? 'selected' : ''} `} onClick={() => setSelectedModeViewer('list')}><h5><BsList size={11} /> Lista</h5></span>
                </div>

                <div>

                </div>
                <div>
                    <input className='search-orders' type='search' placeholder='Buscar' />
                </div>
            </div>

            {selectedModeViewer === 'kanban' &&

                <div className="row vert pad-x3">
                    <div className='row hori space-between gap-x3'>
                        <div className='row vert width-stretch'>
                            <em className='row hori space-between pad-x1'>
                                <div className='row hori gap-x1 center-a'>
                                    <div style={{ width: '7px', height: '7px', backgroundColor: '#4590ed', borderRadius: '50%' }} />
                                    <h4>Novo</h4>
                                </div>
                                <h5 style={{ opacity: '0.5' }}>
                                    {novo?.length || 0}
                                </h5>
                            </em>
                            <div className='col vert gap-x2 hidden scrool-y'>
                                {novo.filter((order) => {
                                    return (
                                        order?.orderId.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.plataform.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.status.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.store?.toLowerCase().includes(filterValue.toLowerCase())
                                    )
                                })
                                    .sort(compare)
                                    .map((item, index) => (
                                        <Card
                                            index={index}
                                            Card={item}
                                            dataIntegrated={integrated}
                                            setSelectedCard={setSelectedCard}
                                            selectedCard={selectedCard}
                                            setVisibleDetail={setVisibleDetail} />
                                    ))}
                            </div>
                        </div>
                        <div className='row vert width-stretch'>
                            <em className='row hori space-between pad-x1'>
                                <div className='row hori gap-x1 center-a'>
                                    <div style={{ width: '7px', height: '7px', backgroundColor: '#4590ed', borderRadius: '50%' }} />
                                    <h4>Recebido</h4>
                                </div>
                                <h5 style={{ opacity: '0.5' }}>
                                    {recebido?.length || 0}
                                </h5>
                            </em>
                            <div className='col vert gap-x2 hidden scrool-y'>
                                {recebido.filter((order) => {
                                    return (
                                        order?.orderId.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.plataform.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.status.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.store?.toLowerCase().includes(filterValue.toLowerCase())
                                    )
                                })
                                    .sort(compare) // Ordenar pela junção de agendamentoDataInicio e agendamentoHoraInicio
                                    .map((item, index) => (
                                        <Card
                                            index={index}
                                            Card={item}
                                            dataIntegrated={integrated}
                                            setSelectedCard={setSelectedCard}
                                            selectedCard={selectedCard}
                                            setVisibleDetail={setVisibleDetail} />
                                    ))}
                            </div>
                        </div>
                        <div className='row vert width-stretch'>
                            <em className='row hori space-between pad-x1'>
                                <div className='row hori gap-x1 center-a'>
                                    <div style={{ width: '7px', height: '7px', backgroundColor: '#4590ed', borderRadius: '50%' }} />
                                    <h4>Entregando</h4>
                                </div>
                                <h5 style={{ opacity: '0.5' }}>
                                    {entregando?.length || 0}
                                </h5>
                            </em>
                            <div className='col vert gap-x2 hidden scrool-y'>
                                {entregando.filter((order) => {
                                    return (
                                        order?.orderId.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.plataform.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.status.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.store?.toLowerCase().includes(filterValue.toLowerCase())
                                    )
                                })
                                    .sort(compare) // Ordenar pela junção de agendamentoDataInicio e agendamentoHoraInicio
                                    .map((item, index) => (
                                        <Card
                                            index={index}
                                            Card={item}
                                            dataIntegrated={integrated}
                                            setSelectedCard={setSelectedCard}
                                            selectedCard={selectedCard}
                                            setVisibleDetail={setVisibleDetail} />
                                    ))}
                            </div>
                        </div>
                        <div className='row vert width-stretch'>
                            <em className='row hori space-between pad-x1'>
                                <div className='row hori gap-x1 center-a'>
                                    <div style={{ width: '7px', height: '7px', backgroundColor: '#4590ed', borderRadius: '50%' }} />
                                    <h4>Cancelado</h4>
                                </div>
                                <h5 style={{ opacity: '0.5' }}>
                                    {cancelado?.length || 0}
                                </h5>
                            </em>
                            <div className='col vert gap-x2 hidden scrool-y'>
                                {cancelado.filter((order) => {
                                    return (
                                        order?.orderId.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.plataform.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.status.toLowerCase().includes(filterValue.toLowerCase()) ||
                                        order?.store?.toLowerCase().includes(filterValue.toLowerCase())
                                    )
                                })
                                    .sort(compare) // Ordenar pela junção de agendamentoDataInicio e agendamentoHoraInicio
                                    .map((item, index) => (
                                        <Card
                                            index={index}
                                            Card={item}
                                            dataIntegrated={integrated}
                                            setSelectedCard={setSelectedCard}
                                            selectedCard={selectedCard}
                                            setVisibleDetail={setVisibleDetail} />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {selectedModeViewer === 'table' &&
                <div className="row vert hidden pad-x3">
                    <Table
                        title={`Orders`}
                        source={[...novo, ...entregando, ...recebido, ...cancelado]}
                        pageSize={50}
                        displayColumns={[0, 2, 3, 4, 5, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18]}
                        celStyleFunction={handleStyleCells}
                        labels={[' ', 'ID', 'Data/Hora', 'Loja', 'Nome', 'Status', 'Categoria', 'Cod. Pedido', ' ', ' ']}
                        columnsWidth={[0, 0, 0, 0, -1, 0, 0, 0, 0, 0]}
                    />
                </div>
            }
            {selectedModeViewer === 'list' && <div className='row vert center-a center-j'>Visualização em Lista indisponivel no momento</div>}
            {visibleDetail && <CardDetailMoral Card={selectedCard} onClose={setVisibleDetail} />}
            {/* {visibleDetail && <OrderShowDetail onClose={setVisibleDetail} order={selectedCard} />} */}
            {/* {configModalOpen && <StreamingConfiguration onClose={() => setConfigModalOpen(false)} />} */}


            {isLoading && <Loader />}
        </div>
    )
};

