export const Favicon = (host, width, height) => {
    const dominio = host?.split('@').pop();
    const faviconGoogle = `https://s2.googleusercontent.com/s2/favicons?domain=${dominio}`;
    return <img src={faviconGoogle} alt="favicon" width={width || 15} height={height || 15} />;
};

export const FaviconUrl = (host) => {
    const dominio = host?.split('@').pop();
    const faviconGoogle = `https://s2.googleusercontent.com/s2/favicons?domain=${dominio}`;
    return faviconGoogle;
};
