import axios from "axios";
import { generateToken } from "./Token";

const API_URL = "https://api.drogaleste.com";

let intervalId = null;

/**
 * Inicia requisições SQL periódicas.
 * @param {string} query - Consulta SQL a ser executada.
 * @param {function} callback - Função de retorno para manipular os dados da consulta.
 */
export async function startSQLRequests(query, callback) {
  intervalId = setInterval(async () => {
    const data = await SQLQuery(query);
    callback(data);
  }, 5000); // Intervalo de 5 segundos
}

/**
 * Para requisições SQL periódicas.
 */
export function stopSQLRequests() {
  clearInterval(intervalId);
}

/**
 * Realiza uma única requisição SQL.
 * @param {string} query - Consulta SQL a ser executada.
 * @returns {Promise} Promise que resolve com os dados da consulta.
 */
export async function singleSQLRequest(query) {
  return await SQLQuery(query);
}

/**
 * Função interna para executar a consulta SQL.
 * @param {string} query - Consulta SQL a ser executada.
 * @returns {Promise} Promise que resolve com os dados da consulta.
 */
async function SQLQuery(query, maxAttempts = 2, currentAttempt = 1) {
  const script = { 
    script: query 
  };

  // Auth['accessToken'] = await generateToken();
  let accessToken = localStorage.getItem('tempToken');

  await new Promise((resolve) => setTimeout(resolve, 1000)); // Espera 1 segundo

  try {
    const response = await axios.post(`${API_URL}/execute`, query, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "text/plain",
        // "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    if (currentAttempt < maxAttempts) {
      if (error.response && error.response.status === 401) {
        console.error("Erro de autenticação. Gerando novo token...");
        try {
          localStorage.removeItem('tempToken');
          accessToken = await generateToken();
        } catch (error) {
          console.error("Erro ao gerar o novo token:", error);
          throw error;
        }
        console.log("Novo token gerado:", accessToken);
      } else if (error?.response && (error.response.status === 429 || error.response.status === 500)) {
        console.error("Muitas requisições ou erro interno. Aguarde um momento antes de fazer outra tentativa.");
        await new Promise((resolve) => setTimeout(resolve, 5000)); // Aguarda 5 segundos antes de fazer uma nova tentativa
      } else {
        console.error("Erro na requisição SQL:", error);
        throw error;
      }
      return await SQLQuery(query, maxAttempts, currentAttempt + 1); // Tenta novamente com a próxima tentativa
    } else {
      throw error;
      // Emitir alerta aqui
    }
  }
}

