// CustomStackedBarChart.js
import React from 'react';
import { Chart } from 'react-google-charts';

export const CustomStackedBarChart = ({ data, containerWidth, containerHeight }) => {
    // Função auxiliar para formatar os dados conforme necessário pelo react-google-charts
    const formatData = (data) => {
        const formattedData = [['Dia da Semana', 'Atual', 'Expectativa']];

        data.forEach(({ name, value1, value2 }) => {
            formattedData.push([name, value1, value2]);
        });

        return formattedData;
    };

    return (
        <div style={{ width: containerWidth, height: containerHeight }}>
            <Chart
                width={containerWidth}
                height={containerHeight}
                chartType="ColumnChart" // Usar ColumnChart para exibir duas colunas
                loader={<div>Carregando Gráfico</div>}
                data={formatData(data)}
                options={{
                    title: null,
                    backgroundColor: 'transparent',
                    legend: { position: 'top' },
                    bar: { groupWidth: '75%' }, // Ajustar a largura das colunas
                    isStacked: true,
                    vAxis: {
                        title: null,
                        minValue: 0,
                        format: '0'
                    },
                    hAxis: {
                        title: 'Dia da Semana'
                    },
                    chartArea: { width: '90%', height: '80%' },
                    colors: ['#8884d8', '#82ca9d'],
                }}
                rootProps={{ 'data-testid': '4' }}
            />
        </div>
    );
};