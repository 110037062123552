// Loader.js
import React from 'react';
import './Loader.css'; // Certifique-se de criar um arquivo CSS com este nome

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
