import { useEffect, useState } from 'react';
import './StepHttpModal.css';
import { ExpanderDefault } from '../../../Components/expander/ExpanderDefault';
import { BsList } from 'react-icons/bs';
import { VscJson } from 'react-icons/vsc';
import DynamicParams from '../../../Components/Inputs/DynamicParams';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/eclipse.css';
import 'codemirror/mode/javascript/javascript';
import { singleSQLRequest } from '../../../Services/Api/GenericRequest';

export const StepHttpModal = ({step, onClose}) => {
    // console.log(step)
    const [method, setMethod] = useState('GET');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [variableMemory, setVariableMemory] = useState(null);
    const [memorys, setMemorys] = useState([]);
    const [fixeResponse, setFixeResponse] = useState(false);
    const [batchSize, setBatchSize] = useState('');
    const [contentField, setContentField] = useState('');
    const [paramsHeaders, setParamsHeaders] = useState([{ name: '', value: '', checked: false }]);
    const [paramsQuery, setParamsQuery] = useState([{ name: '', value: '', checked: false }]);
    const [paramsPaths, setParamsPaths] = useState([{ name: '', value: '', checked: false }]);
    const [paramsBody, setParamsBody] = useState('');
    const [selectedMenu, setSelectedMenu] = useState('Solicitação');

    useEffect(() => {

        if(step){
            setMethod(step?.constants?.find(ct => ct.content === 'method')?.value || 'get');
            setUrl(step?.constants.find(ct => ct.content === 'router')?.value);
            setDescription(step?.description);
            setBatchSize(step?.batch_size);
            setContentField(step?.field_content);
            setMemorys(step?.constants.filter(ct => ct.content === 'memory') || []);
            setParamsHeaders(step?.constants.filter(ct => ct.content === 'headers') || [{ name: false, value: false, checked: false }]);
            setParamsQuery(step?.constants.filter(ct => ct.content === 'query') || [{ name: false, value: false, checked: false }]);
            setParamsPaths(step?.constants.filter(ct => ct.content === 'path') || [{ name: false, value: false, checked: false }]);
            setParamsBody(step?.constants.find(ct => ct.content === 'body')?.value);
        }
    }, [step])



    // Função para gerar o script SQL
    const generateSQLScript = () => {
        // Inicializa o script com uma transação para garantir a atomicidade
        let script = `
        BEGIN TRANSACTION;
        DECLARE @ActionId INT;
        DECLARE @StepId INT;
        IF NOT EXISTS (SELECT 1 FROM SERVICES_ACTIONS WHERE [action] = ${step?.action || 0})
        BEGIN
            INSERT INTO SERVICES_ACTIONS ([status], [service], [description], [code_type], [batch_size], [field_content], [fixed_response])
            VALUES (1, ${step?.service || step?.service}, '${description}', 1, ${batchSize || 0}, ${contentField ? `'${contentField}'` : 'NULL'}, ${Number(fixeResponse) || '0'});
            SET @ActionId = SCOPE_IDENTITY();
        END
        ELSE
        BEGIN
            SET @ActionId = ${step?.action || 0};
            UPDATE SERVICES_ACTIONS
            SET [description] = '${description}', [batch_size] = ${batchSize || 0}
            ,[field_content] = ${contentField ? `'${contentField}'` : 'NULL'}
            ,[fixed_response] = ${`'${Number(fixeResponse) || '0'}'`}
            WHERE [action] = @ActionId;
        END
        `;

        // Inicializa o script com uma transação para garantir a atomicidade
        script += `
        BEGIN TRANSACTION;
        DECLARE @ActionId INT;
        DECLARE @StepId INT;

        IF NOT EXISTS (SELECT 1 FROM SERVICES_ROUTINES_STEPS WHERE [step] = ${step?.step || 0})
        BEGIN
            INSERT INTO SERVICES_ROUTINES_STEPS ([status], [service], [action], [routine])
            VALUES (1, ${step?.service || step?.service}, @ActionId, ${step?.routine || step?.routine});
            SET @StepId = SCOPE_IDENTITY();
        END
        ELSE
        BEGIN
            SET @StepId = ${step?.step || 0};
        END;
        `;

        // Função auxiliar para formatar os valores do script SQL
        const formatValue = (value) => {
            // Se o valor contiver aspas simples, substitua por duas aspas simples para evitar problemas de SQL injection
            return value?.replace(/'/g, "''");
        };

        // Função auxiliar para gerar a parte do script que insere ou atualiza registros
        const upsertValue = (content, field, value, enabled) => {
            if(!content || !field || !value) return '';
            
            console.log('enabled', +enabled)
            return`
                IF EXISTS (SELECT 1 FROM SERVICES_CONSTANTS WHERE [action] = @ActionId AND [content] = '${content}' AND [field] = '${field}')
                BEGIN
                    UPDATE SERVICES_CONSTANTS
                    SET [value] = '${value}'
                    ,[enabled] = ${+enabled}
                    WHERE [action] = @ActionId AND [content] = '${content}' AND [field] = '${field}';
                END
                ELSE
                BEGIN
                    INSERT INTO SERVICES_CONSTANTS ([service], [action], [content], [field], [value], [enabled])
                    VALUES (${step?.service || step?.service}, @ActionId, '${content}', '${field}', '${value}', ${+enabled});
                END;
                `
            };

        // Adiciona os valores dos headers ao script
        const headerFields = paramsHeaders.map(header => header.name).filter(name => name);
        paramsHeaders.forEach(header => {
            if (header.name && header.value) {
                script += upsertValue('headers', formatValue(header.name), formatValue(header.value), header.checked);
            }
        });

        // Adiciona os valores dos parâmetros de consulta ao script
        const queryFields = paramsQuery.map(query => query.name).filter(name => name);
        paramsQuery.forEach(query => {
            if (query.name && query.value) {
                script += upsertValue('query', formatValue(query.name), formatValue(query.value), query.checked);
            }
        });

        // Adiciona os valores dos parâmetros de consulta ao script
        const pathFields = paramsPaths.map(path => path.name).filter(name => name);
        paramsPaths.forEach(path => {
            if (path.name && path.value) {
                script += upsertValue('path', formatValue(path.name), formatValue(path.value), path.enabled);
            }
        });

        // Adiciona o valor do corpo da requisição ao script
        if (paramsBody) {
            script += upsertValue('body', 'body', formatValue(paramsBody), 1);
        }

        // Adiciona o valor do método da requisição ao script
        script += upsertValue('method', 'method', formatValue(method), 1);

        // Adiciona o valor da URL ao script
        script += upsertValue('router', 'router', formatValue(url), 1);

        // Adiciona o valor do variableMemory ao script
        if(variableMemory)
            script += upsertValue('memory', 'data', formatValue(variableMemory), +fixeResponse);

        // Remove headers que não estão mais presentes
        if (headerFields.length > 0) {
            script += `
            DELETE FROM SERVICES_CONSTANTS
            WHERE [action] = @ActionId AND [content] = 'headers' AND [field] NOT IN (${headerFields.map(name => `'${formatValue(name)}'`).join(', ')});
            `;
        } else {
            script += `
            DELETE FROM SERVICES_CONSTANTS
            WHERE [action] = @ActionId AND [content] = 'headers';
            `;
        }

        // Remove query parameters que não estão mais presentes
        if (queryFields.length > 0) {
            script += `
            DELETE FROM SERVICES_CONSTANTS
            WHERE [action] = @ActionId AND [content] = 'query' AND [field] NOT IN (${queryFields.map(name => `'${formatValue(name)}'`).join(', ')});
            `;
        } else {
            script += `
            DELETE FROM SERVICES_CONSTANTS
            WHERE [action] = @ActionId AND [content] = 'query';
            `;
        }

        // Remove path parameters que não estão mais presentes
        if (pathFields.length > 0) {
            script += `
            DELETE FROM SERVICES_CONSTANTS
            WHERE [action] = @ActionId AND [content] = 'path' AND [field] NOT IN (${pathFields.map(name => `'${formatValue(name)}'`).join(', ')});
            `;
        } else {
            script += `
            DELETE FROM SERVICES_CONSTANTS
            WHERE [action] = @ActionId AND [content] = 'path';
            `;
        }
        // Finaliza a transação
        script += `
        COMMIT TRANSACTION;
        `;

        return script;
    };


    const handleSave = async () => {
        // Gera o script SQL
        const sqlScript = generateSQLScript();
        console.log(sqlScript)

        // try{
        //     const response = await singleSQLRequest(sqlScript);
        //     alert('Salvo com sucesso!')

        // }catch(err){
        //     console.log(err)
        // }

    }

    return (
        <div className="modal step-http">
            <section className='content vert gap-x3'>
                <div className='hori space-between pad-l5 pad-t5 pad-r5'>
                    <div className="close-button" onClick={() => onClose(false)} />
                    <h3>{step ? 'Editar' : 'Adicionar'} Rotina</h3>
                    <h5></h5>
                </div>
                <div className='scrool-y pad-l5 pad-r5' style={{backgroundColor: 'transparent'}}>
                    <div className='vert gap-x3'>
                        <div className='vert'>
                            <div className='vert gap-x2'>
                                <label className='vert'>
                                    <h5>Descrição:</h5>
                                    <input value={description}/>
                                </label>
                                <div className='hori flex gap-x2'>
                                    <label className='vert flex-0'>
                                        <h5>Metodo:</h5>
                                        <select value={method}>
                                            <option value={`get`}>GET</option>
                                            <option value={`post`}>POST</option>
                                            <option value={`patch`}>PATCH</option>
                                            <option value={`delete`}>DELETE</option>
                                            <option value={`options`}>OPTIONS</option>
                                        </select>
                                    </label>
                                    <label className='vert flex-1'>
                                        <h5>Url:</h5>
                                        <input value={url}/>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='vert gap-x3'>
                            <div style={{ borderBottom: '3px solid #ebebeb', paddingBottom: '0.1rem' }}>
                                <label className={`tab-item ${selectedMenu === 'Solicitação' ? 'selected' : ''}`} onClick={() => setSelectedMenu('Solicitação')}>Solicitação</label>
                                <label className={`tab-item ${selectedMenu === 'Resposta' ? 'selected' : ''}`} onClick={() => setSelectedMenu('Resposta')}>Resposta</label>
                                <label className={`tab-item ${selectedMenu === 'Configuração' ? 'selected' : ''}`} onClick={() => setSelectedMenu('Configuração')}>Configuração</label>
                            </div>
                            {selectedMenu === 'Solicitação' && (
                                <div className='vert'>
                                    <ExpanderDefault header={<div className="row hori align-center gap-x2"><BsList size={18}/>Headers</div>} expanded={paramsHeaders.filter(prm => prm?.name).length > 0}>
                                        <div className={`pad-l2 pad-r2`}>
                                            <DynamicParams
                                                paramType={`Headers`}
                                                params={paramsHeaders}
                                                setParams={setParamsHeaders}
                                                />
                                        </div>
                                    </ExpanderDefault>
                                    <ExpanderDefault header={<div className="row hori align-center gap-x2"><BsList size={18}/>Query</div>} expanded={paramsQuery.filter(prm => prm?.name).length > 0}>
                                        <div className={`pad-l2 pad-r2`}>
                                            <DynamicParams
                                                paramType={`Query`}
                                                params={paramsQuery}
                                                setParams={setParamsQuery}
                                                />
                                        </div>
                                    </ExpanderDefault>
                                    <ExpanderDefault header={<div className="row hori align-center gap-x2"><BsList size={18}/>Path</div>} expanded={paramsPaths.filter(prm => prm?.name).length > 0}>
                                        <div className={`pad-l2 pad-r2`}>
                                            <DynamicParams
                                                paramType={`Path`}
                                                params={paramsPaths}
                                                setParams={setParamsPaths}
                                                />
                                        </div>
                                    </ExpanderDefault>
                                    <ExpanderDefault header={<div className="row hori align-center gap-x2"><VscJson size={18}/>Body</div>} expanded={true}>
                                        <div className={`pad-l2 pad-r2`}>
                                            <CodeMirror
                                                // ref={textAreaRef}
                                                value={paramsBody}
                                                options={{
                                                    mode: 'javascript',
                                                    theme: 'eclipse',
                                                    lineNumbers: false,
                                                    // readOnly: true
                                                }}
                                                onBlur={(editor, event) => {
                                                    const value = editor.getValue();
                                                    setParamsBody(value);
                                                }}
                                                editorDidMount={editor => {
                                                    editor.setSize("calc(100% - 0px)", "150px");
                                                    editor.getWrapperElement().style.fontFamily = 'Courier New';
                                                    editor.getWrapperElement().style.fontSize = '14px';
                                                    editor.refresh();
                                                }}
                                            />
                                        </div>
                                    </ExpanderDefault>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='pad-x3' style={{backgroundColor: 'whitesmoke'}}>
                    <div className='hori gap-x2 pad-l5 pad-r5 pad-b2'>
                        <button type='save' onClick={handleSave}>Salvar</button>
                        <button type='cancel' onClick={onClose}>Cancelar</button>
                    </div>
                </div>
            </section>
        </div>
    )
}