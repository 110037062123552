import { BsSearch } from "react-icons/bs";

export const Input = ({ type, className, placeholder, onChange, value }) => {

    const inputContainerStyle = {
        position: 'relative', // Para que a posição do ícone seja relativa ao container do input
    };

    const inputStyle = {
        borderRadius: '1rem',
        padding: '0.4rem 0.7rem',
        paddingLeft: type === 'search' ? '1.5rem' : '0.7rem', // Ajuste o padding esquerdo conforme necessário
    };

    const iconStyle = {
        position: 'absolute',
        left: '0.5rem',
        top: '50%', // Centralizar verticalmente
        transform: 'translateY(-50%)', // Centralizar verticalmente
    };

    return (
        <div className="row hori center-a input-container width-stretch" style={inputContainerStyle}>
            {type === 'search' && <BsSearch style={iconStyle} size={12} opacity={0.3}/>}
            <input type={type} className={className} style={inputStyle} placeholder={placeholder} onChange={onChange} value={value}/>
        </div>
    );
};
