import React, { useState, useEffect } from 'react';
import { CgClose, CgLock, CgLockUnlock } from "react-icons/cg";
import { IoLockClosed, IoLockOpenOutline } from "react-icons/io5";
import { RiDraggable } from "react-icons/ri";

import './DynamicParams.css';
const DynamicParams = ({ params = [{ name: '', value: '', checked: false, encrypted: false }], setParams, paramType, isEncrypt = false, viewDecrypt = false, viewCheck = true, disabledKey = false, disabledValue = false, autoIncrement = true }) => {
    const [focusedInputIndex, setFocusedInputIndex] = useState(-1);

    useEffect(() => {
        if (autoIncrement && params.length === 0) {
            addParam();
        }
    }, [params]);

    const addParam = () => {
        setParams([...params, { name: '', value: '', checked: false, encrypted: false }]);
    };

    const editItem = (index, newName, newValue) => {
        const newParams = [...params];
        if (newName !== undefined) {
            newParams[index].name = newName;
        }
        if (newValue !== undefined) {
            newParams[index].value = newValue;
        }
        setParams(newParams);
    };

    const toggleCheckbox = (index) => {
        const newParams = [...params];
        newParams[index].checked = !newParams[index].checked;
        setParams(newParams);
    };

    const toggleEncryption = (index) => {
        const newParams = [...params];
        newParams[index].encrypted = !newParams[index].encrypted;
        setParams(newParams);
    };

    const removeItem = (index) => {
        const newParams = params.filter((_, i) => i !== index);
        if (autoIncrement && newParams.length === 0) {
            addParam();
        } else {
            setParams(newParams);
        }
    };

    const handleFocus = (index, isCheckboxChecked) => {
        setFocusedInputIndex(index);
        if (autoIncrement && index === params.length - 1) addParam();
        if (!isCheckboxChecked) toggleCheckbox(index);
    };

    return (
        <div className='vert gap-x1'>
            {params.map((item, index) => (
                <div className='row hori center-a'>                
                    <div style={{opacity: '0.5'}}>
                        <RiDraggable/>
                    </div>
                    <div key={index} className={`item-params2 ${focusedInputIndex === index ? 'edit' : ''} gap-x1`}>
                        <div>
                            {viewCheck && (
                                <input
                                    type="checkbox"
                                    checked={item.checked}
                                    onChange={() => toggleCheckbox(index)}
                                    />
                            )}
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder={`Add ${paramType} Name`}
                                value={item.name}
                                onFocus={() => handleFocus(index, item.checked)}
                                onChange={(e) => editItem(index, e.target.value, undefined)}
                                onBlur={() => setFocusedInputIndex(-1)}
                                disabled={disabledKey}
                            />
                            {/* <InputSuggestion 
                                    value={item.name} 
                                    placeholder={`Add ${paramType} Name`}
                                    // onChange={setUrl} 
                                    suggestionsMap={suggestionData} 
                                /> */}
                        </div>
                        <div>
                            {isEncrypt &&
                                <div className='pointer' onClick={() => viewDecrypt ? toggleEncryption(index) : false}>
                                    {viewDecrypt ? (
                                        item.encrypted ? <IoLockClosed /> : <IoLockOpenOutline />
                                    ): 
                                    <IoLockClosed />}
                                </div>
                            }
                        </div>
                        <div>
                            <input
                                type={item.encrypted ? "password" : "text"}
                                placeholder={`Add ${paramType} Value`}
                                value={item.value}
                                onFocus={() => handleFocus(index, item.checked)}
                                onChange={(e) => editItem(index, undefined, e.target.value)}
                                onBlur={() => setFocusedInputIndex(-1)}
                                disabled={disabledValue}
                            />
                        </div>
                        <div>
                            <CgClose size={12} className="icon-close" onClick={() => removeItem(index)} />
                        </div>
                        
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DynamicParams;
