import React, { useState, useEffect } from 'react';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import { singleSQLRequest } from '../../Services/Api/GenericRequest';
import { LoaderSpinner } from '../Loaders/Spinner';
import { RxReload } from 'react-icons/rx';

import './Table.css';
export const Table = ({ title, source, query, primaryKey, handleSelectedRow, pageSize = 10, displayColumns, onDataUpdate, paginated = true, showDetails, rowStyleFunction, celStyleFunction, showSearch = true, handleAdd, handleEdit, handleDelete, tools, orderBy = 'ASC', multiSelection = false, labels = [], columnsWidth = [] }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAllRows, setSelectAllRows] = useState(false);


    const [isLoading, setIsLoading] = useState(true);
    const [sortDirection, setSortDirection] = useState({});
    const [sortedData, setSortedData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');


    // Lógica para renderizar os números das páginas
    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPageDisplay = 5; // Máximo de números de páginas a serem exibidos
        const startPage = Math.max(1, currentPage - Math.floor(maxPageDisplay / 2));
        const endPage = Math.min(Math.ceil(totalRows / pageSize), startPage + maxPageDisplay - 1);

        // Botão para ir para a primeira página
        if (startPage > 1) {
            pageNumbers.push(
                <button
                    key="first"
                    onClick={() => handlePageChange(1)}
                    className={`radio-button-icon-option ${currentPage === 1 ? 'selected' : ''}`}
                    disabled={isLoading} // Desabilita o botão se isLoading for true
                >
                    1
                </button>
            );
            // Adicione um separador (por exemplo, "...")
            if (startPage > 2) {
                pageNumbers.push(<span key="separator">...</span>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={`radio-button-icon-option ${currentPage === i ? 'selected' : ''}`}
                    disabled={isLoading} // Desabilita o botão se isLoading for true
                >
                    {i}
                </button>
            );
        }

        // Botão para ir para a última página
        if (endPage < Math.ceil(totalRows / pageSize)) {
            // Adicione um separador (por exemplo, "...")
            if (endPage < Math.ceil(totalRows / pageSize) - 1) {
                pageNumbers.push(<span key="separator">...</span>);
            }

            pageNumbers.push(
                <button
                    key="last"
                    onClick={() => handlePageChange(Math.ceil(totalRows / pageSize))}
                    className={`radio-button-icon-option ${currentPage === Math.ceil(totalRows / pageSize) ? 'selected' : ''}`}
                    disabled={isLoading} // Desabilita o botão se isLoading for true
                >
                    {Math.ceil(totalRows / pageSize)}
                </button>
            );
        }

        return pageNumbers;
    };



    useEffect(() => {
        if (source) {
            setSortedData(source);
            setColumns(Object.keys(source[0] || {}));
            setTotalRows(source.length); // Atualize o total de linhas quando source for alterado
        } else if (query) {
            fetchSQLData(query);
        }
    }, [source, query, currentPage, onDataUpdate]);

    const fetchTotalRows = async (query) => {
        try {
            const countQuery = `SELECT COUNT(*) AS TotalRows FROM (${query}) AS SubQuery`;
            const response = await singleSQLRequest(countQuery);
            return response[0].TotalRows;
        } catch (error) {
            console.error("Error fetching total rows:", error);
            return 0;
        }
    };

    const fetchSQLData = async (query) => {
        const offset = (currentPage - 1) * pageSize;
        const paginatedQuery = `${query} ORDER BY ${primaryKey} ${orderBy} OFFSET ${offset} ROWS FETCH NEXT ${pageSize} ROWS ONLY`;

        try {
            setIsLoading(true);
            const response = await singleSQLRequest(paginatedQuery);
            console.log(response)
            setSortedData(response);
            setColumns(Object.keys(response[0] || {}));

            // Atualize o total de linhas
            const totalRows = await fetchTotalRows(query);
            setTotalRows(totalRows);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        if (multiSelection && handleSelectedRow) {
            handleSelectedRow(selectedRows.map(rowIndex => sortedData[rowIndex]));
        } else if (handleSelectedRow) {
            handleSelectedRow(sortedData[selectedRow]);
        }
    }, [selectedRows, selectedRow, multiSelection, sortedData]);


    const toggleRowSelection = (index) => {
        if (multiSelection && handleSelectedRow) {
            if (selectedRows.includes(index)) {
                setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
            } else {
                setSelectedRows([...selectedRows, index]);
            }
            handleSelectedRow(selectedRows.map(rowIndex => sortedData[rowIndex]));
        } else if (handleSelectedRow) {
            setSelectedRow(index);
            handleSelectedRow(sortedData[index]);
        }
    };

    const toggleSelectAllRows = () => {
        setSelectAllRows(!selectAllRows);
        // Atualize as linhas selecionadas com base no estado de seleção de todas as linhas
        if (!selectAllRows) {
            const allRowIndexes = sortedData.map((_, index) => index);
            setSelectedRows(allRowIndexes);
        } else {
            setSelectedRows([]);
        }
    };

    const isSelected = (index) => {
        return multiSelection ? selectedRows.includes(index) : selectedRow === index;
    };

    const toggleSortDirection = (column) => {
        const newSortDirection = { ...sortDirection };
        newSortDirection[column] = newSortDirection[column] === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);
        sortData(column, newSortDirection[column]);
    };

    const sortData = (column, direction) => {
        const sorted = [...sortedData];
        sorted.sort((a, b) => {
            if (a[column] > b[column]) {
                return direction === 'asc' ? 1 : -1;
            }
            if (a[column] < b[column]) {
                return direction === 'asc' ? -1 : 1;
            }
            return 0;
        });
        setSortedData(sorted);
    };

    const getSortIcon = (column) => {
        if (sortDirection[column] === 'asc') {
            return <BsCaretUpFill />;
        } else if (sortDirection[column] === 'desc') {
            return <BsCaretDownFill />;
        } else {
            return null;
        }
    };

    const handlePageChange = async (page) => {
        setCurrentPage(page);
        await fetchSQLData(query);
    };

    const filteredColumns = displayColumns
        ? columns.filter((_, index) => displayColumns.includes(index))
        : columns;

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleReloadData = async () => {
        await fetchSQLData(query);
    };

    const getColumnWidthStyle = (width) => {
        switch (width) {
            case 0:
                return 'auto'; // Ajustável
            case -1:
                return '100%'; // 1fr (flexbox)
            default:
                return `${width}px`; // Valor numérico específico
        }
    };


    return (
        <div className="app-table">
            {showSearch ? (
                <div className='row hori mar-b3 gap-x1' style={{ display: 'grid', gridTemplateColumns: '1fr auto', justifyContent: 'space-between' }}>
                    {title && <div style={{ width: '100%' }}> <h4>{title}</h4> </div>}

                    <div className='row hori stretch-width gap-x3'>
                        <div className='row hori gap-x2'>
                            {Array.isArray(tools) && tools?.map((tool, index) => (
                                <div key={index} className='tools-content'>
                                    {tool}
                                </div>
                            ))}
                        </div>
                        <div className='row hori gap-x1'>
                            <input
                                type='search'
                                placeholder='Buscar'
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ width: title ? 'auto' : '100%' }}
                            />
                            <button className='button-icon' onClick={handleReloadData}>
                                <RxReload />
                            </button>

                        </div>
                    </div>

                </div>
            ) : <div></div>}
            <div className='table-content'>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type='checkbox'
                                    checked={selectAllRows}
                                    onChange={toggleSelectAllRows}
                                />
                            </th>

                            {/* {filteredColumns.map((column, index) => (
                                <th key={index} onClick={() => toggleSortDirection(column)}>
                                    {labels[index] || column} {getSortIcon(column)}
                                </th>
                            ))} */}
                            {filteredColumns.map((column, idx) => {
                                const columnWidth = getColumnWidthStyle(columnsWidth[idx]);

                                return (
                                    <td key={idx} style={{ width: columnWidth }}>
                                        {labels[idx] || column} {getSortIcon(column)}
                                    </td>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData && sortedData
                            .filter((row) =>
                                Object.values(row).some(
                                    (value) =>
                                        (typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())) ||
                                        (typeof value === 'number' && value.toString().includes(searchTerm))
                                )
                            )
                            .map((row, index) => {

                                const rowStyle = rowStyleFunction !== undefined ? rowStyleFunction(row) : '';

                                // Verifica se qualquer célula na linha atende à condição
                                const isInactiveRow = columns.some(column => {
                                    const isStatusColumn = ['ativo', 'status', 'enable'].includes(column.toLowerCase());
                                    const isInactive = ['inativo', 'f', '0', 'false'].includes(String(row[column]).toLowerCase());
                                    return isStatusColumn && isInactive;
                                });

                                // Aplica a classe "inativo" à linha inteira se a condição for verdadeira
                                const rowClassName = isInactiveRow ? 'inativo' : '';

                                return (
                                    <tr key={index} onClick={() => toggleRowSelection(index)} style={rowStyle ? rowStyle : null} className={`${isSelected(index) ? "selected" : ""} ${rowClassName}`}>

                                        <td><input type='checkbox' checked={isSelected(index)} onChange={() => toggleRowSelection(index)} /></td>

                                        {filteredColumns.map((column, idx) => {

                                            const cellStyle = celStyleFunction != undefined ? celStyleFunction(idx, row, row[column]) : false;

                                            if (column === 'url') {
                                                return (
                                                    <td key={idx}>
                                                        <div style={{ overflow: 'hidden', width: '3rem', height: '3rem', borderRadius: '0.3rem', backgroundColor: '#fff', padding: '0.5rem' }}>
                                                            <img src={row[column]} style={{ width: '100%', height: '100%' }} />
                                                        </div>
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td key={idx}>

                                                        {cellStyle ? (() => { return cellStyle })() :
                                                            (() => {
                                                                switch (typeof row[column]) {
                                                                    case 'object':
                                                                        return JSON.stringify(row[column]).toString();
                                                                    case 'boolean':
                                                                        return row[column] ? 'true' : 'false';
                                                                    // Adicione casos para outros tipos de dados, se necessário
                                                                    default:
                                                                        return row[column];
                                                                }
                                                            })()
                                                        }
                                                    </td>
                                                );
                                            }
                                        })}
                                    </tr>
                                );
                            })}
                    </tbody>

                </table>
            </div>
            {showDetails ?
                <div className='hori width-stretch space-between pad-x2'>
                    <div className={`hori gap-x2`}>
                        {handleAdd &&
                            <button
                                type="submit"
                                onClick={handleAdd}>
                                Novo
                            </button>
                        }
                        {handleEdit &&
                            <button
                                disabled={(multiSelection && selectedRows.length === 0) || (!multiSelection && selectedRow === null)}
                                onClick={() => {
                                    if (multiSelection) {
                                        handleEdit(selectedRows.map(rowIndex => sortedData[rowIndex]));
                                    } else {
                                        handleEdit(sortedData[selectedRow]);
                                    }
                                }}>
                                Editar
                            </button>
                        }
                        {handleDelete &&
                            <button
                                disabled={(multiSelection && selectedRows.length === 0) || (!multiSelection && selectedRow === null)}
                                onClick={() => {
                                    if (multiSelection) {
                                        handleDelete(selectedRows.map(rowIndex => sortedData[rowIndex]));
                                    } else {
                                        handleDelete(sortedData[selectedRow]);
                                    }
                                }}
                                style={{ backgroundColor: "lightcoral" }}>
                                Deletar
                            </button>
                        }
                    </div>
                    <div className={`hori align-center`}>
                        {paginated &&
                            <>
                                <div className='hori align-center pad-x3 gap-x2'>
                                    <h5>Visualizando:</h5>
                                    <div className='div hori align-center'>
                                        <h5>{sortedData.length}</h5>
                                        <>/</>
                                        <h5>{totalRows}</h5>
                                    </div>
                                </div>
                                {query && (
                                    <div className='hori gap-x3 pad-x3'>
                                        <div className='hori align-center gap-x2'>
                                            <select value={pageSize}>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </div>
                                        <div className='hori gap-x1'>
                                            {renderPageNumbers()}
                                        </div>
                                    </div>
                                )}
                            </>
                        }

                    </div>
                </div>
                : <div></div>}
            <>
                {isLoading && <LoaderSpinner />}
            </>
        </div>

    );
};
