import React from 'react';
import salyHomem from '../../assets/saly/Saly-10.png';
import salyFoguete from '../../assets/saly/Saly-43.png';
import './LandingPage.css';
import icon from '../../assets/ico.svg';

const LandingPage = () => {
  return (
    <div className="container">
      <header className="header">
        <a href="#" className="logo">
          <span className="sr-only"><img src={icon}/></span>
        </a>
        <nav className="nav">
          <a href="#" className="nav-link">Integrações</a>
          <a href="#" className="nav-link">Planos</a>
          <a href="#" className="nav-link">Sobre</a>
          <a href="#" className="nav-link">Contato</a>
        </nav>
      </header>
      <main className="main">
        <section className="hero">
          <div className="hero-content">
            <div className="text-content">
              <h1>Integre seus sistemas com facilidade</h1>
              <p>Conecte suas aplicações com centenas de serviços populares usando nossa plataforma de integrações.</p>
              <div className="buttons">
                <a href="#" className="btn-primary">Começar Agora</a>
                <a href="#" className="btn-secondary">Ver Planos</a>
              </div>
            </div>
            <div className="image-content">
              <img src={salyHomem} alt="Saly 3D Illustration" className="hero-image" />
            </div>
          </div>
        </section>
        <section className="features">
          <div className="feature-content">
            <h1>Conecte seus sistemas com facilidade</h1>
            <p>Integre suas aplicações com centenas de serviços populares como CRMs, ERPs, e-commerce, redes sociais e muito mais.</p>
            <div className="feature-grid">
              <div className="feature-item">
                <h3>Conectividade Plug-and-Play</h3>
                <p>Conecte suas aplicações em minutos com nossos conectores pré-construídos.</p>
              </div>
              <div className="feature-item">
                <h3>Fluxos de Trabalho Automatizados</h3>
                <p>Crie fluxos de trabalho automatizados entre seus sistemas para aumentar a produtividade.</p>
              </div>
              <div className="feature-item">
                <h3>Monitoramento e Análises</h3>
                <p>Acompanhe o desempenho de suas integrações com relatórios em tempo real.</p>
              </div>
              <div className="feature-item">
                <h3>Segurança e Conformidade</h3>
                <p>Mantenha seus dados seguros com criptografia e autenticação de dois fatores.</p>
              </div>
              <div className="feature-item">
                <h3>Suporte Dedicado</h3>
                <p>Conte com uma equipe de especialistas para ajudá-lo a configurar e gerenciar suas integrações.</p>
              </div>
              <div className="feature-item">
                <h3>Escalabilidade Ilimitada</h3>
                <p>Escale suas integrações conforme sua empresa cresce, sem preocupações com infraestrutura.</p>
              </div>
            </div>
            <div className="buttons">
              <a href="#" className="btn-primary">Começar Agora</a>
              <a href="#" className="btn-secondary">Ver Planos</a>
            </div>
            {/* <div className="image-content">
              <img src={salyFoguete} alt="Saly 3D Illustration" className="hero-image" />
            </div> */}
          </div>
        </section>
        <section className="cta">
          <div className="cta-content">
            <h2>Experimente a plataforma de integrações mais poderosa</h2>
            <p>Conecte suas aplicações em minutos e automatize seus fluxos de trabalho.</p>
            <form className="cta-form">
              <input type="email" placeholder="Insira seu email" className="cta-input" />
              <button type="submit" className="btn-primary">Experimente Grátis</button>
            </form>
            <p className="cta-terms">
              Experimente gratuitamente por 14 dias. <a href="#" className="cta-link">Termos e Condições</a>
            </p>
          </div>
        </section>
        <section className="pricing">
          <div className="pricing-content">
            <h2>Planos para todos os tipos de negócios</h2>
            <p>Escolha o plano que melhor se adapta às suas necessidades e comece a integrar seus sistemas hoje mesmo.</p>
            <div className="pricing-grid">
              <div className="pricing-item">
                <h3>Starter</h3>
                <strong className="price">R$99 <span>por mês</span></strong>
                <ul className="pricing-features">
                  <li>10 Integrações</li>
                  <li>1 Fluxo de Trabalho Automatizado</li>
                  <li>Suporte Básico</li>
                </ul>
                <button className="btn-primary">Assinar Agora</button>
              </div>
              <div className="pricing-item">
                <h3>Pro</h3>
                <strong className="price">R$299 <span>por mês</span></strong>
                <ul className="pricing-features">
                  <li>Integrações Ilimitadas</li>
                  <li>Fluxos de Trabalho Ilimitados</li>
                  <li>Suporte Prioritário</li>
                  <li>Relatórios Avançados</li>
                </ul>
                <button className="btn-primary">Assinar Agora</button>
              </div>
              <div className="pricing-item">
                <h3>Enterprise</h3>
                <strong className="price">Sob Consulta</strong>
                <ul className="pricing-features">
                  <li>Integrações Personalizadas</li>
                  <li>Suporte Dedicado</li>
                  <li>Consultoria Técnica</li>
                </ul>
                <button className="btn-primary">Fale Conosco</button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LandingPage;
