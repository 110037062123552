import { MdOutlineMoreVert } from "react-icons/md";
import moment from 'moment';

import { Colors } from "../../../Functions/Colors";
import { FormatToDay } from "../../../Functions/FormatToDay";

import './Card.css';
import { RiCheckDoubleLine, RiCheckFill } from "react-icons/ri";
import { VscCalendar, VscLinkExternal } from "react-icons/vsc";
import capitalizeName from "../../../Functions/CapitalizeName";
import { BsAt, BsBagCheck, BsCalendar2Check, BsLightning, BsSearch, BsShop, BsShopWindow, BsTag, BsWhatsapp } from "react-icons/bs";
import { TbSearch } from "react-icons/tb";
import { Favicon } from "../../../Functions/Favicon";
import { Definitions } from "../Definitions";
import { AiFillAlert } from "react-icons/ai";
import { CiShoppingTag } from "react-icons/ci";
import { useRef, useState } from "react";

export const Card = ({ index, Card, dataIntegrated, setSelectedCard, selectedCard, setVisibleDetail }) => {

    const isSelected = selectedCard?.orderId === Card.orderId;
    const [isHovered, setIsHovered] = useState(false);

    const isIntegrated = dataIntegrated.some(item => item.codigo === Card.orderId);
    const itemIntegrated = dataIntegrated.find(item => item.codigo === Card.orderId) || {};
    const Dia = isIntegrated ? FormatToDay(itemIntegrated.dataHoraIntegracao) + ' às ' + itemIntegrated?.dataHoraIntegracao?.split('T')[1]?.slice(0, 5) + 'h'
        : FormatToDay(Card.schedulingStart) + ' às ' + Card.schedulingStart?.split('T')[1]?.slice(0, 5) + 'h';


    const isLate = (dateTime, defaultTIme) => {

        const dataAtual = new Date();
        const dataParametro = new Date(dateTime);
        const diffEmMilissegundos = dataAtual - dataParametro;
        const diffEmMinutos = Math.floor(diffEmMilissegundos / (1000 * 60));

        if (diffEmMinutos <= defaultTIme) {
            return true
        }
    }

    return (
        <div key={Card.orderId} className={`card-order pad-x3 ${isSelected ? 'selected' : ''}`} style={{ '--i': index }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={() => setSelectedCard(Card)}>
            <div className="row vert">
                <div className='row hori width-stretch space-between'>

                    <div className="row hori center-a gap-x1">
                        <span className="col hidden" style={{ backgroundColor: Card.defaultColor, borderRadius: '50%', height: '0.9rem' }}>{Favicon(Card?.domainUrl)}</span>
                        <h5 className="bold-x1">{capitalizeName(Card.storeName)}</h5>
                    </div>

                    <div><MdOutlineMoreVert /></div>

                </div>

                <div className="row hori gap-x1">
                    <h5>{Card.OrderIdPlatform}</h5>
                    |
                    <h5>{Card.orderId}</h5>

                </div>

                <div className="row hori gap-x2 center-a">
                    <span><CiShoppingTag size={11} /> {Card.totalPriceFinish.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                    <span><BsBagCheck /> {Card.totalItems}</span>
                </div>

                <div className='separator' />
                <div className="row hori gap-x2 center-a width-stretch space-between">
                    <div className="row hori gap-x2">

                        <div className='tag-status radios-x2 pad-x1 center-a' style={{ backgroundColor: Colors.statusOrder[Card.statusLiveship], color: '#fff', fontSize: '0.55rem' }}>
                            {Card?.statusLiveship}
                        </div>
                        <span className={`tag-date-time nivel${isLate(Card.schedulingStart, Definitions.limitTimeSla)}`}>
                            <em>{Dia}</em>
                            {isIntegrated ? (
                                <RiCheckDoubleLine size={12} />
                            ) : (
                                <RiCheckFill size={12} />
                            )}
                        </span>
                        {Card.isExpress && <BsLightning size={12} />}
                        {Card.isScheduling && <BsCalendar2Check size={12} />}
                    </div>

                    <div>
                        {/* <BsWhatsapp size={12} cursor={'pointer'} /> */}
                        {(isHovered || isSelected) && <VscLinkExternal size={12} cursor={'pointer'} onClick={() => setVisibleDetail(true)} />}
                    </div>
                </div>
            </div>
        </div>
    )
}


