import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

const Mapa = ({ altura = '100vh', largura = '100%', centro = [-23.5505, -46.6333], zoom = 13, coordenadas }) => {
    const mapaRef = useRef(null);
console.log(coordenadas)
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    useEffect(() => {
        const mapa = L.map(mapaRef.current).setView(centro, zoom);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(mapa);

        // Adiciona um marcador para cada loja
        coordenadas?.forEach(loja => {
            L.marker(loja.coordenadas).addTo(mapa).bindPopup(loja.nome);
        });

        return () => {
            mapa.remove();
        };
    }, [centro, zoom, coordenadas]);

    return <div ref={mapaRef} style={{ height: altura, width: largura }} />;
};

export default Mapa;
