import React from 'react';
import { BsCheckCircle, BsCircle, BsCircleFill } from 'react-icons/bs';
import './CardDetailModelIntegration.css';
import { Colors } from '../../Functions/Colors';

export const CardDetailModelIntegration = ({ Card }) => {

    const PendingTimeline = ({ status, title, state }) => {
        return (
            <div className={`tag-timeline ${state} row hori gap-x1`}>
                <div className='row'>
                    <h5>10:00</h5>
                </div>
                <div className='row vert center-a gap-x1'>
                    <>
                        {state === 'actual' ? <BsCircleFill fill={'var(--theme-color)'}/> : (state === 'before' ? <BsCheckCircle fill='#b8b8b8' /> : <BsCircle fill='#b8b8b8' />)}
                    </>
                    {status !== 'CAN' && <div className='line' />}
                    
                </div>
                <div className='row width-stretch' style={{ paddingBottom: '1rem' }}>
                    <div className={`card ${state} row width-stretch gap-x2`}>

                        <div className='card-status' style={{ backgroundColor: Colors.statusOrder[status], color: '#fff', fontSize: '0.55rem' }}>
                            {status}
                        </div>

                        <h4>
                            {title}
                        </h4>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="row vert width-stretch height-x">

            <div className='row vert gap-x2 mar-x3'>
                {[{ status: 'EMI', title: 'Emitido' }, 
                  { status: 'GER', title: 'Gerado' }, 
                  { status: 'FAT', title: 'Faturado' }, 
                  { status: 'SEP', title: 'Em Separação' }, 
                  { status: 'ENT', title: 'Entregando' }, 
                  { status: 'ENP', title: 'Pendente' }, 
                  { status: 'CAN', title: 'Cancelado' }].map((item, index, array) => (
                    <PendingTimeline 
                      key={item.status} 
                      status={item.status} 
                      title={item.title} 
                      state={Card.statusLiveship === item.status ? 'actual' : (index < array.findIndex(i => i.status === Card.statusLiveship) ? 'before' : 'after')} 
                    />
                  ))}
            </div>

        </div>
    )
}
