import React, { useState } from 'react';
import './SidebarMaster.css';
import { HubbitLogo } from '../Logo/HubbitLogo';
import { VscExtensions, VscSettingsGear } from 'react-icons/vsc';
import { BsAirplaneEngines, BsBarChart, BsBarChartFill, BsBarChartLine, BsBell, BsBellFill, BsCalendar3Range, BsCalendar3RangeFill, BsChatRightDots, BsChatRightDotsFill, BsChevronDown, BsChevronRight, BsCup, BsCupHot, BsCupHotFill, BsCursor, BsCursorFill, BsDash, BsDatabase, BsDatabaseFill, BsFillGrid1X2Fill, BsFillGridFill, BsGear, BsGearFill, BsGraphUp, BsGrid, BsHandbag, BsHandbagFill, BsPerson, BsPersonFill, BsPlayFill, BsPlus, BsStackOverflow } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { HubitLogotipo } from '../Logo/HubitLogotipo';
import logo from '../../assets/ico.svg';
import Logo from '../../assets/Logo';
export const SidebarMaster = (handleExpanded) => {

    const [menuSelected, setMenuSelected] = useState('home');
    const [isExpanded, setIsExpanded] = useState(false);

    const [selectedStartMenu, setSelectedStartMenu] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(null);

    const handleMenuSelected = (menu) => {
        setMenuSelected(menu);
    }

    const Menus = {
        home: [
            {
                name: "Dashboard",
                icon: <BsBarChartLine />,
                to: '/',
                content: [
                    {
                        name: "Pedidos",
                        to: '/home',
                        content: []
                    },
                    {
                        name: "Vendas",
                        to: "/home",
                        content: []
                    },
                    {
                        name: "Clientes",
                        to: "/home",
                        content: []
                    }
                ]
            },
            {
                name: "Aplicativos",
                icon: <VscExtensions />,
                to: '/home',
                content: [
                    {
                        name: "Liveship",
                        to: '/liveship',
                        content: []
                    },
                    {
                        name: "Chat",
                        to: '/whatsapp',
                        content: []
                    },
                ]
            },
            {
                name: "HubStore",
                icon: <BsHandbag />,
                to: '/home',
                content: [
                    {
                        name: "Aplicativos",
                        to: '/home',
                        content: []
                    },
                    {
                        name: "Integrações",
                        to: '/integrations',
                        content: []
                    },
                ]
            },
        ],
        database: [
            {
                name: "Dados",
                icon: <BsGear />,
                to: "/home",
                content: [
                    {
                        name: "New Client",
                        to: '/',
                        content: [

                        ]
                    }
                ]
            },
            {
                name: "Product",
                icon: <BsGear />,
                to: "/home",
                content: [
                    {
                        name: "New Product",
                        to: '/products',
                        content: [

                        ]
                    }
                ]
            },
        ],
        person: [
            {
                name: "Users",
                icon: <BsGear />,
                to: "/home",
                content: [
                    {
                        name: "Access",
                        to: '/home',
                        content: [

                        ]
                    },
                    {
                        name: "Permission",
                        to: '/home',
                        content: [

                        ]
                    },
                ]
            },
        ]
    }

    const RenderMenus = (menus) => {

        return menus && menus.map((item, i) => {
            if (item.content && item.content.length > 0) {
                return (
                    <div key={i}>
                        <ExpanderMenu icon={item.icon} title={item.name} content={item.content} RenderMenus={RenderMenus} />
                    </div>
                )
            } else {
                return (
                    <Link className={`menu-item ${selectedMenu?.name === item.name ? 'selected' : ''} pad-x1 align-center space-between `} key={item.name} to={item.to} onClick={() => { setSelectedMenu(item); }}>
                        {item.name} <BsPlayFill size={15} className="icon-hover-only" />
                    </Link>
                );
            }
        })
    }

    return (
        <div className="sidebar row hori">
            <div className='col vert' style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto' }}>
                <div>
                    <div className={`logo refresh pointer`} onClick={() => setIsExpanded(!isExpanded)}>
                        <Logo />
                    </div>
                </div>
                <div>
                    <span data-tooltip={!isExpanded ? `Dashboard` : ''} data-flow="right">
                        <Link className={`menu-icon ${menuSelected === 'home' ? 'selected' : ''}`} onClick={() => handleMenuSelected('home')}>
                            {menuSelected === 'home' ? <BsFillGridFill /> : <BsGrid />}
                        </Link>
                    </span>
                    <span data-tooltip={!isExpanded ? `Dados` : ''} data-flow="right">
                        <Link className={`menu-icon ${menuSelected === 'database' ? 'selected' : ''}`} onClick={() => handleMenuSelected('database')}>
                            {menuSelected === 'database' ? <BsDatabaseFill /> : <BsDatabase />}
                        </Link>
                    </span>
                    <span data-tooltip={!isExpanded ? `Usuários` : ''} data-flow="right">
                        <Link className={`menu-icon ${menuSelected === 'person' ? 'selected' : ''}`} onClick={() => handleMenuSelected('person')}>
                            {menuSelected === 'person' ? <BsPersonFill size={18} /> : <BsPerson size={18} />}
                        </Link>
                    </span>
                </div>
                <div>

                    <span data-tooltip={!isExpanded ? `Configurações` : ''} data-flow="right">
                        <Link className={`menu-icon ${menuSelected === 'settings' ? 'selected' : ''}`} onClick={() => handleMenuSelected('settings')}>
                            {menuSelected === 'settings' ? <BsGearFill /> : <BsGear />}
                        </Link>
                    </span>
                </div>
            </div>

            <div className={`sidebar-expanded ${isExpanded ? 'transition' : ''}`}>
                {isExpanded && (
                    <div className='row pad-x1'>
                        <div className='row center-j width-stretch vert gep-x2'>
                            <div className={``} onClick={() => setIsExpanded(!isExpanded)}><HubbitLogo name={'hubit'} /></div>
                            <div className='row vert' >
                                {RenderMenus(Menus[menuSelected])}
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};


const ExpanderMenu = ({ icon, title, content, RenderMenus }) => {
    const [isExpanded, setIsExpanded] = useState(false); // Estado independente para controlar a expansão

    const toggleExpander = () => {
        setIsExpanded(!isExpanded);
    };

    // Não redefina isExpanded com base no selectedMenu
    return (
        <div>
            <div className='expander-menu'>
                <div className='title-content center-a no-select' onClick={toggleExpander}>
                    <div className='row gap-x2 center-a bold-x2 no-select'>
                        <h3 className='no-wrap gap-x2'>{title}</h3>
                    </div>
                    <div className='row'>
                        {isExpanded ? <BsDash size={12} /> : <BsPlus size={12} />}
                    </div>
                </div>
                <div className='submenu-content vert gap-x1'>
                    {isExpanded && content && content.length > 0 && (RenderMenus(content))}
                </div>
            </div>
        </div>
    );
}