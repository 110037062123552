import React, { useState } from 'react';
import './Logo.css';

const Logo = ({color = "#fff", width = '100%', height = '100%'}) => {
  const [isVertical, setIsVertical] = useState(false);

  const handleClick = () => {
    setIsVertical(!isVertical);
  };

  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 95.000000 110.000000"
      preserveAspectRatio="xMidYMid meet"
      onClick={handleClick}
      className={`logo ${isVertical ? 'vertical' : 'horizontal'}`}
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,110.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M436 1045 c-23 -24 -27 -65 -24 -250 l3 -170 63 -3 62 -3 0 204 c0
          185 -2 205 -18 220 -23 21 -66 22 -86 2z"
        />
        <path
          d="M122 927 c-22 -23 -22 -28 -22 -378 l0 -356 23 -21 c29 -27 57 -28
          85 -2 22 21 22 23 22 380 0 357 0 359 -22 380 -29 26 -59 25 -86 -3z"
        />
        <path
          d="M749 931 l-24 -19 0 -362 0 -362 24 -19 c32 -26 57 -24 86 7 l25 27
          0 349 0 349 -25 24 c-29 30 -55 32 -86 6z"
        />
        <path
          d="M414 466 c-3 -7 -4 -97 -2 -199 3 -165 5 -187 22 -206 24 -26 67 -27
          89 -3 15 16 17 47 17 220 l0 202 -60 0 c-42 0 -62 -4 -66 -14z"
        />
      </g>
    </svg>
  );
};

export default Logo;
