import cronParser from 'cron-parser';
import moment from 'moment';

export function nextExecution(cronExpression) {
    try {
        const interval = cronParser.parseExpression(cronExpression);
        const nextDate = interval.next().toDate();
        const saoPauloDateTime = moment(nextDate).tz('America/Sao_Paulo'); // Convertendo para fuso horário de São Paulo
        
        return saoPauloDateTime.format('DD/MM/yyyy HH:mm:ss'); 
    } catch (error) {
        console.error('Erro ao calcular próxima execução:', error);
        return null; // Retornando null em caso de erro
    }
}

export function verificaStatusExecucao(cronExpression, lastExecution) {
    function intervaloExecucao(cronExpression) {
        let interval = cronParser.parseExpression(cronExpression);
        let proximaData1 = interval.next().toDate();
        let proximaData2 = interval.next().toDate();

        // Calcula o intervalo em milissegundos entre as duas próximas execuções
        let intervalo = proximaData2.getTime() - proximaData1.getTime();

        return intervalo;
    }

    // Calcula o intervalo
    let intervalo = intervaloExecucao(cronExpression);

    // Ajusta a data da última execução para o momento exato em que deveria ter ocorrido
    let ultimaExecucao = new Date(lastExecution);
    let momentoCron = cronParser.parseExpression(cronExpression).prev().toDate();
    ultimaExecucao.setUTCHours(momentoCron.getUTCHours());
    ultimaExecucao.setUTCMinutes(momentoCron.getUTCMinutes());

    // Se a última execução já passou do momento cron, avance para a próxima ocorrência
    if (ultimaExecucao.getTime() > new Date().getTime()) {
        ultimaExecucao = cronParser.parseExpression(cronExpression).prev().toDate();
        ultimaExecucao.setUTCHours(momentoCron.getUTCHours());
        ultimaExecucao.setUTCMinutes(momentoCron.getUTCMinutes());
    }

    // Adiciona o intervalo à data/hora ajustada da última execução
    let proximaExecucao = new Date(ultimaExecucao.getTime() + intervalo);

    // Verifica se a próxima execução é posterior à data e hora atuais
    if (proximaExecucao.getTime() > new Date().getTime()) {
        return true; // Próxima execução está no futuro
    } else {
        return false; // Próxima execução está no passado ou presente
    }
}

export function getProgramacaoCron(expression) {
    if(!expression) return '';
    // console.log('getProgramacaoCron', expression)
    // expression = '*/2 * * * *'
    const partes = expression?.split(' ').filter(part => part.trim() !== '');

    // console.log(partes)


    if (partes.length !== 5) {
        return 'Programação inválida';
    }

    const [minutos, horas, dias, meses, diasDaSemana] = partes;
    // console.log(minutos, horas, dias, meses, diasDaSemana)

    // Descrições específicas
    if (minutos === '*' && horas === '*' && dias === '*' && meses === '*' && diasDaSemana === '*') {
        return 'À cada minuto';
    } else if (minutos.includes('*/')) {
        const intervalo = minutos.split('/')[1];
        return `À cada ${intervalo} minutos`;
    } else if (minutos === '0' && horas === '12' && dias === '*' && meses === '*' && diasDaSemana === '*') {
        return 'meio-dia todos os dias';
    } else if (minutos === '0' && horas === '12' && dias.includes('/') && meses === '*' && diasDaSemana === '*') {
        const intervalo = dias.split('/')[1];
        return `meio-dia a cada ${intervalo} dias`;
    } else if (minutos === '0' && horas === '0' && dias === '*' && meses === '*' && diasDaSemana === '1') {
        return 'meia-noite toda segunda-feira';
    } else if (minutos === '0' && horas === '0' && dias === '1' && meses === '*' && diasDaSemana === '*') {
        return 'meia-noite no primeiro dia de cada mês';
    } else if (minutos === '0' && horas === '0' && dias === 'L' && meses === '*' && diasDaSemana === '*') {
        return 'meia-noite no último dia do mês';
    } else if (minutos === '0' && horas === '0' && dias === '*' && meses === '*' && diasDaSemana === '5') {
        return 'meia-noite todas as sextas-feiras';
    } else if (minutos.includes('*/') && dias.includes(',')) {
        const diasSemana = dias.split(',').join(', ');
        const intervalo = minutos.split('/')[1];
        return `a cada ${intervalo} minutos nas ${diasSemana}`;
    } else if (minutos === '0' && horas === '0' && dias === '*' && meses === '*' && diasDaSemana.includes('1-5')) {
        return 'meia-noite de segunda a sexta';
    }

    return 'Programação inválida';
}

export function getIntervalCron(expression) {
    const partes = expression.split(' ').filter(part => part.trim() !== '');

    if (partes.length !== 5) {
        return { quantidade: null, unidade: null };
    }

    const [minutos, horas, dias, meses, diasDaSemana] = partes;

    // Função auxiliar para extrair intervalo de qualquer parte
    const extrairIntervalo = (parte, unidade) => {
        if (parte === '*') {
            return { quantidade: 1, unidade };
        } else if (parte.includes('*/')) {
            const intervalo = parseInt(parte.split('/')[1]);
            return { quantidade: intervalo, unidade };
        } else if (!isNaN(parseInt(parte))) {
            return { quantidade: parseInt(parte), unidade };
        }
        return null;
    };

    // Verifica se o intervalo é de 1 hora (0 * * * *)
    if (minutos === '0' && horas === '*' && dias === '*' && meses === '*' && diasDaSemana === '*') {
        return { quantidade: 1, unidade: 'hora' };
    }

    // Verifica intervalo em minutos
    let intervalo = extrairIntervalo(minutos, 'minuto');
    if (intervalo) return intervalo;

    // Verifica intervalo em horas
    intervalo = extrairIntervalo(horas, 'hora');
    if (intervalo) return intervalo;

    // Verifica intervalo em dias
    intervalo = extrairIntervalo(dias, 'dia');
    if (intervalo) return intervalo;

    // Verifica intervalo em meses
    intervalo = extrairIntervalo(meses, 'mês');
    if (intervalo) return intervalo;

    // Verifica intervalo em dias da semana
    intervalo = extrairIntervalo(diasDaSemana, 'dia da semana');
    if (intervalo) return intervalo;

    return { quantidade: null, unidade: null };
}


export function getDaysOfTheWeek(expression) {
    // expression = '0 0 * * 1-5'
    const partes = expression.split(' ').filter(part => part.trim() !== '');

    if (partes.length !== 5) {
        return { dias: [], horario: { hora: null, minuto: null, segundo: null } };
    }

    const [minutos, horas, dias, meses, diasDaSemana] = partes;

    // Array de dias da semana
    const diasDaSemanaArray = [];
    if (diasDaSemana === '*') {
        // Se diasDaSemana for '*', retorna todos os dias da semana (0 a 6)
        for (let i = 0; i <= 6; i++) {
            diasDaSemanaArray.push(i);
        }
    } else {
        if (diasDaSemana.includes(',')) {
            diasDaSemanaArray.push(...diasDaSemana.split(',').map(Number));
        } else if (diasDaSemana.includes('-')) {
            const [start, end] = diasDaSemana.split('-').map(Number);
            for (let i = start; i <= end; i++) {
                diasDaSemanaArray.push(i);
            }
        } else {
            diasDaSemanaArray.push(Number(diasDaSemana));
        }
    }

    // Objeto de horário
    const horario = {
        hora: horas === '*' ? null : parseInt(horas),
        minuto: minutos === '*' ? null : parseInt(minutos),
        segundo: 0 // Considerando o padrão cron não usa segundos
    };

    return { dias: diasDaSemanaArray, horario };
}


export function getDaysOfTheMonth(expression) {
    const partes = expression.split(' ').filter(part => part.trim() !== '');

    if (partes.length !== 5) {
        return { dias: [], horario: { hora: null, minuto: null } };
    }

    const [minutos, horas, dias, meses, diasDaSemana] = partes;

    // Array de dias do mês
    const diasDoMesArray = [];
    if (dias === '*') {
        // Se dias for '*', retorna todos os dias do mês
        for (let i = 1; i <= 31; i++) {
            diasDoMesArray.push(i);
        }
    } else {
        if (dias.includes(',')) {
            diasDoMesArray.push(...dias.split(',').map(Number));
        } else if (dias.includes('-')) {
            const [start, end] = dias.split('-').map(Number);
            for (let i = start; i <= end; i++) {
                diasDoMesArray.push(i);
            }
        } else {
            diasDoMesArray.push(Number(dias));
        }
    }

    // Objeto de horário
    const horario = {
        hora: horas === '*' ? null : parseInt(horas),
        minuto: minutos === '*' ? null : parseInt(minutos)
    };

    return { dias: diasDoMesArray, horario };
}

export function isValidExpression(expression){
    try {
        cronParser.parseExpression(expression);
        return true;
    } catch (error) {
        return false;
    }
}