export default function capitalizeName(name) {
    if (!name) return name;
    
    try {
        const words = name.toLowerCase().split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        return words.join(' ');
    } catch (err) {
        return name;
    }
}
