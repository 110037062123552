import { BsBookmarkStarFill, BsChevronRight, BsDiamond, BsDiamondFill, BsLink, BsShop } from "react-icons/bs";
import './Integrations.css';
import { useEffect, useState } from "react";
import { singleSQLRequest } from "../../Services/Api/GenericRequest";
import { Favicon } from "../../Functions/Favicon";
import Loader from "../../Components/Loaders/Loader";
import { Link, useNavigate } from "react-router-dom";
import { Ifood } from "./Platforms/Ifood";
import { Input } from "../../Components/Inputs/Input";
import { IntegrationsDetails } from "./IntegrationsDetails";

export const Integrations = () => {
    const [filteredIntegrations, setFilteredIntegrations] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [dataIntegrations, setDataIntegrations] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await singleSQLRequest('SELECT * FROM SERVICES ORDER BY [name]');
            console.log(response)
            setDataIntegrations(response);
            setFilteredIntegrations(response); // Garante que ambos os estados sejam atualizados
        } catch (error) {
            console.error('Erro ao buscar integrações:', error);
        }
        setIsLoading(false);
    };

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        if (value) {
            // Filtrar a lista de integrações com base no termo de busca
            const filteredIntegrations = dataIntegrations.filter(integration =>
                integration.name?.toLowerCase().includes(value.toLowerCase())
            );
            // Atualizar apenas o estado das integrações filtradas
            setFilteredIntegrations(filteredIntegrations);
        } else {
            // Se não houver termo de busca, resetar para a lista original de integrações
            setFilteredIntegrations(dataIntegrations);
        }
    };
    const handleselectedService = (item) => {
        console.log(item)
        setSelectedService(item);
    }

    const handleIntegrationClick = (item) => {
        setSelectedService(item)
        navigate(`/integrations/${item.name}`, { state: { service: item } });
    };

    return (
                <div className="app-integrations">
                    <div className="content">

                        <div className='header row vert pad-x3 gap-x2'>
                            <div className='row hori'>
                                <h1><BsDiamond fill='var(--header-icon-color)' /> Integrações</h1>
                            </div>
                            <div className='row hori'>
                                <h5><BsDiamond size={10} /> Home / <BsDiamondFill size={10} /> HubStore </h5>
                            </div>
                        </div>

                        <div className='tools'>

                        </div>

                        <div className="row vert pad-l5 pad-t5 pad-r5 pad-x3 gap-x3 shadow-bottom" style={{backgroundColor: '#fbfbfb'}}>

                            <div className="row vert gap-x2">
                                <h1>Plataformas</h1>
                                <h4 style={{ opacity: '0.5' }}>Expanda seus horizontes abrangendo diversas plataformas, desde redes sociais até marketplaces.</h4>
                            </div>

                            <div className="row hori">

                                <div className="row hori gap-x2 flex-1">      
                                    <button className="radio-button-model1 selected">Todas</button>
                                    <button className="radio-button-model1">E-commerce</button>
                                    <button className="radio-button-model1">CRM</button>
                                    <button className="radio-button-model1">Suporte</button>
                                </div>

                                <div className="flex-0">
                                    <Input type={'search'} onChange={handleSearchChange} value={searchTerm} placeholder={'Buscar...'} />
                                </div>

                            </div>

                        </div>
                        <div className="scrool-y pad-x5"  >
                            
                            <div className="row hori gap-x3 wrap" style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))'}}>
                                {filteredIntegrations && filteredIntegrations.map((card, index) => {

                                    return(
                                        <div className="card row vert pad-x3 shadow" style={{display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '120px', backgroundColor: '#fff'}}
                                            onClick={() => handleIntegrationClick(card)}>
                                            <div className="row hori  gap-x2" style={{display: 'grid', gridTemplateColumns: 'auto 1fr auto'}}>
                                                <div className="col center-a center-j" 
                                                    style={{
                                                    backgroundColor: 'var(--theme-color)',
                                                    borderRadius: '1rem',
                                                    width: '35px',
                                                    height: '35px',
                                                    margin: '1px'                                          
                                                }}>
                                                    {Favicon(card.domain)}
                                                </div>
                                                <div className="col vert gap-x1">                                                
                                                    <h2>{card.name} </h2>
                                                    <em style={{fontSize: '0.8rem'}}>Plataforma</em>
                                                </div>
                                                <div className="col">
                                                    <em style={{fontSize: '0.8rem', opacity: '0.3'}}>{card?.status ? 'Instalado' : 'Indisponível'}</em>
                                                </div>
                                            </div>

                                            <div className="row hori pad-t3">
                                                <h4 style={{opacity: '0.6'}}>{card.description} </h4>
                                            </div>

                                            <div className="row vert col-reverse text-e">
                                                <BsChevronRight fill="#5c2bff"/>
                                            </div>
                                            
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                        
                        {isLoading && <Loader />}

                    </div>

                </div>
    )
}


