import axios from 'axios';

const API_URL = 'http://api.drogaleste.com/v2';

const Auth = {};

async function generateToken() {

  let response;

    if(!localStorage.getItem('tempToken')){
        response = await axios.post(`${API_URL}/oauth`, {
            clientId: 'agB1AG4AaQBvAHIAbwBsAGkAdgBlAGkAcgBhAC4AbABhAEAAaABvAHQAbQBhAGkAbAAuAGMAbwBtAA==',
            clientSecret: 'MAA1ADcAMQA3ADMANQA0ADUAMgA1AEoAdQBuAGkAbwByACAAbwBsAGkAdgBlAGkAcgBhAA==',
        });

        console.log('Token foi gerado');
        Auth['accessToken'] = response?.data?.accessToken;
        localStorage.setItem('tempToken', response?.data?.accessToken);

    }
    
  return response?.data?.accessToken;
}

export function getToken() {
  return localStorage.getItem('tempToken');
}

export {generateToken, Auth}