export const Colors = {
    method: {
        'GET': '#3589ff', // verde
        'POST': '#8c0ec2', // amarelo
        'PUT': '#e64a19', // laranja
        'DELETE': '#cc3f3f', // roxo
        'PATCH': '#cc6529', // laranja escuro
        'OPTIONS': '#F44336', // vermelho
    },
    statusCode:{
        200: '#1bc357', // verde
        204: '#99a4d0',
        400: '#FFC107', // amarelo
        401: '#e64a19', // laranja
        403: '#a45d9b', // laranja
        404: '#FF5722', // laranja
        500: '#F44336', // vermelho
    },
    statusOrder: {
        'EMI': '#3e9afc',
        'GER': '#b984fe',
        'FAT': '#ff65c1',
        'SEP': '#fa9b00',
        'PE0': '#fff',
        'RET': '#db71b4',
        'ENT': '#55ca3a',
        'ENP': '#fe857f',
        'CAN': '#a6b2bf',
    }
}