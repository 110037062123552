
import Avatar from 'react-avatar';
import './WhatsappHub.css';
import { useEffect, useState } from 'react';
import { BsDiamond, BsSearch, BsSend } from 'react-icons/bs';
import { Input } from '../../Components/Inputs/Input';
export const WhatsappHub = () => {
    const [selectedChat, setSelectedChat] = useState('');
    

    return (
        <div className='app-whatsapp-hub'>
            <div className='header row vert pad-x3 gap-x2'>
                <div className='row hori'>
                    <h1><BsDiamond fill='var(--theme-color)' /> Chat</h1>
                </div>
                <div className='row hori'>
                    <h5><BsDiamond size={10} /> Extensões / <BsDiamond size={10} /> Serviços </h5>
                </div>
            </div>
            <div className='chat-content row hori width-stretch'>
                <div className='col vert gap-x4 pad-x3'>
                    <div className='row hori center-a pad-x2 gap-x1'>
                        <Input type='search' placeholder={'Pesquisar ou começar uma nova conversa'}/>
                    </div>
                    <div className='row vert gap-x2 hidden scrool-y'>
                        {conversations.map((contact, index) => (
                            <div key={index} className={`item-conversation ${selectedChat === contact.name ? 'selected' : ''} row hori gap-x2 pad-x2`} onClick={() => setSelectedChat(contact.name)}>
                                <div className='row'>
                                    <span className='width-x5 radios-x3 hidden' style={{ backgroundColor: 'lightblue' }}>
                                        <Avatar
                                            name={contact.name}
                                            size="32"
                                            round={true}
                                        />
                                    </span>
                                </div>
                                <div className='row hori width-stretch'>
                                    <div className='row hori width-stretch space-between'>
                                        <h4 className='bold-x1'>{contact.name}</h4>
                                        <h5>{contact.dateConversationLast}</h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='col vert'>
                    <div className='row header pad-x4 center-a gap-x2'>
                        <Avatar
                            name={selectedChat}
                            size="32"
                            round={true}
                        />
                        <h3>{selectedChat}</h3>
                    </div>
                    <div className='row hori strecth height-x'>
                        {selectedChat && conversations.map((chat, index) => {
                            if (chat.name === selectedChat) {
                                return (
                                    <div key={index} className='conversation-history'>
                                        {chat.chatHistory.map((message, index) => (
                                            <div key={index} className={`message ${message.sender === 'Você' ? 'sent' : 'received'}`}>
                                                <p>{message.message}</p>
                                                <span className='timestamp'>{message.timestamp}</span>
                                            </div>
                                        ))}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>

                    <div className='row pad-x4 center-a gap-x2'>
                        <input type='search' placeholder='Mensagem' style={{ padding: '0.5rem 1rem' }} />
                    </div>

                </div>

                <div className='col'>

                </div>
            </div>
        </div>
    )
}

const conversations = [
    {
        urlPhoto: 'https://example.com/photo1.jpg',
        name: 'Amigo 1',
        dateConversationLast: 'Ontem',
        fixed: false,
        chatHistory: [
            {
                sender: 'Amigo 1',
                message: 'E aí! Tudo tranquilo?',
                timestamp: 'Ontem, 14:30'
            },
            {
                sender: 'Você',
                message: 'Oi! Sim, tudo certo por aqui e contigo?',
                timestamp: 'Ontem, 14:35'
            },
            {
                sender: 'Amigo 1',
                message: 'Também estou bem. O que você fez hoje?',
                timestamp: 'Ontem, 14:40'
            },
            {
                sender: 'Você',
                message: 'Apenas trabalhando no meu projeto de faculdade. E você?',
                timestamp: 'Ontem, 14:45'
            },
            {
                sender: 'Amigo 1',
                message: 'Legal! Eu fui ao cinema com alguns amigos. Foi divertido!',
                timestamp: 'Ontem, 14:50'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo2.jpg',
        name: 'Família',
        dateConversationLast: 'Hoje',
        fixed: true,
        chatHistory: [
            {
                sender: 'Família',
                message: 'Bom dia! Como estão todos?',
                timestamp: 'Hoje, 08:00'
            },
            {
                sender: 'Você',
                message: 'Bom dia! Estamos bem, obrigado por perguntar.',
                timestamp: 'Hoje, 08:05'
            },
            {
                sender: 'Família',
                message: 'Que bom! Algum plano para o fim de semana?',
                timestamp: 'Hoje, 08:10'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo3.jpg',
        name: 'Colega de Trabalho',
        dateConversationLast: 'Hoje',
        fixed: false,
        chatHistory: [
            {
                sender: 'Colega de Trabalho',
                message: 'Bom dia! Você recebeu o e-mail que enviei ontem?',
                timestamp: 'Hoje, 10:30'
            },
            {
                sender: 'Você',
                message: 'Bom dia! Sim, recebi. Vou responder assim que possível.',
                timestamp: 'Hoje, 10:35'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo4.jpg',
        name: 'Grupo de Estudo',
        dateConversationLast: 'Ontem',
        fixed: false,
        chatHistory: [
            {
                sender: 'Você',
                message: 'Bom dia, pessoal! Alguém pode me ajudar com a questão 3?',
                timestamp: 'Ontem, 15:00'
            },
            {
                sender: 'Membro 1',
                message: 'Claro! Qual é a sua dúvida?',
                timestamp: 'Ontem, 15:05'
            },
            {
                sender: 'Membro 2',
                message: 'Eu posso ajudar também. Qual é o problema?',
                timestamp: 'Ontem, 15:10'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo5.jpg',
        name: 'Chef',
        dateConversationLast: 'Hoje',
        fixed: false,
        chatHistory: [
            {
                sender: 'Chef',
                message: 'Olá! Hoje temos um novo prato especial no menu. Venha experimentar!',
                timestamp: 'Hoje, 12:00'
            },
            {
                sender: 'Você',
                message: 'Excelente! Estou ansioso para experimentar.',
                timestamp: 'Hoje, 12:05'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo1.jpg',
        name: 'Vida',
        dateConversationLast: 'Ontem',
        fixed: false,
        chatHistory: [
            {
                sender: 'Vida',
                message: 'Olá! Como vai?',
                timestamp: 'Ontem, 10:00'
            },
            {
                sender: 'Você',
                message: 'Estou bem, obrigado! E você?',
                timestamp: 'Ontem, 10:05'
            },
            {
                sender: 'Vida',
                message: 'Também estou bem. O que você está fazendo?',
                timestamp: 'Ontem, 10:10'
            },
            {
                sender: 'Você',
                message: 'Estou trabalhando no meu projeto de interface de conversação para o WhatsApp.',
                timestamp: 'Ontem, 10:15'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo2.jpg',
        name: 'Comercial',
        dateConversationLast: 'Hoje',
        fixed: false,
        chatHistory: [
            {
                sender: 'Comercial',
                message: 'Bom dia! Temos uma promoção especial esta semana. Gostaria de saber mais?',
                timestamp: 'Hoje, 09:30'
            },
            {
                sender: 'Você',
                message: 'Bom dia! Pode me dar mais detalhes sobre a promoção?',
                timestamp: 'Hoje, 09:35'
            }
        ]
    },
    {
        urlPhoto: '',
        name: 'Novo Número (Você)',
        dateConversationLast: 'Hoje',
        fixed: false,
        chatHistory: [] // Histórico vazio para um novo número
    },
    {
        urlPhoto: 'https://example.com/photo3.jpg',
        name: 'Pablo Vittar',
        dateConversationLast: 'Ontem',
        fixed: false,
        chatHistory: [
            {
                sender: 'Pablo Vittar',
                message: 'Oi! Tudo bem?',
                timestamp: 'Ontem, 18:00'
            },
            {
                sender: 'Você',
                message: 'Oi! Tudo sim, e você?',
                timestamp: 'Ontem, 18:05'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo4.jpg',
        name: 'Amigo Secreto',
        dateConversationLast: 'Ontem',
        fixed: false,
        chatHistory: [
            {
                sender: 'Amigo Secreto',
                message: 'Olá! Ansioso pelo nosso amigo secreto este ano?',
                timestamp: 'Ontem, 20:00'
            },
            {
                sender: 'Você',
                message: 'Claro! Estou animado para descobrir quem me tirou!',
                timestamp: 'Ontem, 20:05'
            }
        ]
    },

    {
        urlPhoto: 'https://example.com/photo1.jpg',
        name: 'Maria Silva',
        dateConversationLast: 'Ontem',
        fixed: false,
        chatHistory: [
            {
                sender: 'Maria Silva',
                message: 'Oi! Como vai você?',
                timestamp: 'Ontem, 10:00'
            },
            {
                sender: 'Você',
                message: 'Oi Maria! Estou bem, obrigado por perguntar. E você?',
                timestamp: 'Ontem, 10:05'
            },
            {
                sender: 'Maria Silva',
                message: 'Estou bem também, obrigada! O que você está fazendo?',
                timestamp: 'Ontem, 10:10'
            },
            {
                sender: 'Você',
                message: 'Estou trabalhando em alguns projetos pessoais. E você?',
                timestamp: 'Ontem, 10:15'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo2.jpg',
        name: 'João Santos',
        dateConversationLast: 'Hoje',
        fixed: false,
        chatHistory: [
            {
                sender: 'João Santos',
                message: 'Bom dia! Como estão as coisas?',
                timestamp: 'Hoje, 09:30'
            },
            {
                sender: 'Você',
                message: 'Bom dia João! As coisas estão indo bem, obrigado. E você?',
                timestamp: 'Hoje, 09:35'
            }
        ]
    },
    {
        urlPhoto: '',
        name: 'Novo Contato',
        dateConversationLast: 'Hoje',
        fixed: false,
        chatHistory: [] // Histórico vazio para um novo contato
    },
    {
        urlPhoto: 'https://example.com/photo3.jpg',
        name: 'Patrícia Oliveira',
        dateConversationLast: 'Ontem',
        fixed: false,
        chatHistory: [
            {
                sender: 'Patrícia Oliveira',
                message: 'Olá! Como você está?',
                timestamp: 'Ontem, 18:00'
            },
            {
                sender: 'Você',
                message: 'Oi! Estou bem, obrigado. E você?',
                timestamp: 'Ontem, 18:05'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo4.jpg',
        name: 'Lucas Ferreira',
        dateConversationLast: 'Hoje',
        fixed: false,
        chatHistory: [
            {
                sender: 'Lucas Ferreira',
                message: 'Oi! Tudo bem contigo?',
                timestamp: 'Hoje, 12:00'
            },
            {
                sender: 'Você',
                message: 'Oi Lucas! Tudo tranquilo, e contigo?',
                timestamp: 'Hoje, 12:05'
            }
        ]
    },
    {
        urlPhoto: 'https://example.com/photo5.jpg',
        name: 'Fernanda Souza',
        dateConversationLast: 'Ontem',
        fixed: false,
        chatHistory: [
            {
                sender: 'Fernanda Souza',
                message: 'E aí, tudo beleza?',
                timestamp: 'Ontem, 20:00'
            },
            {
                sender: 'Você',
                message: 'Oi Fernanda! Tudo ótimo, e você?',
                timestamp: 'Ontem, 20:05'
            }
        ]
    }
];
