import { useEffect, useState } from 'react';
import io from 'socket.io-client';
import moment from 'moment';
import { OrderModel, Items } from './OrderModel';

export const UseSocketData = (onDataUpdate, onIntegratedUpdate) => {

    let fullOrdersFarmaciasApp = {};
    const [serverUri, setServerUri] = useState(localStorage.getItem('serverAddress'));
    const [UrlWebSocket, setUrlWebSocket] = useState(serverUri);


    useEffect(() => {
        // const socket = io(`http://${UrlWebSocket}`);
        const socket = io(`https://server.drogaleste.com:3000`);


        const handleFunctions = {
            handleFarmaciasApp: (response) => {

                const newObj = Object.assign(response, fullOrdersFarmaciasApp);
                fullOrdersFarmaciasApp = newObj;

                const allOrders = [];
                const stores = Object.keys(newObj);
                stores.forEach(store => {

                    const orders = Object.values(newObj[store]).flat();
                    orders.forEach(order => {
                        // Definindo tempo previsto de entrega
                        const dataSaida = moment(order.shipping.out_at || order.shipping.created_at, "YYYY-MM-DD HH:mm:ss");
                        const dataHoraEntregaPrevista = dataSaida.add(order.shipping.estimated_delivery_time, 'minutes');

                        const model = new OrderModel();
                        model.platform = 'Farmacias App';
                        model.defaultColor = '#c1eae8'
                        model.OrderIdPlatform = order.shipping.id;
                        model.dateTime = moment(order.created_at, "MM-DD-YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");
                        model.storeId = store;
                        model.storeName = getStoreName(store);
                        model.statusPlatform = order.status.id;
                        model.statusLiveship = getDescriptionStatus(order.status.id);
                        model.category = getCategory(order.status.id);
                        model.orderId = order.ref_id;
                        model.isExpress = order?.tipo === 'EXPRESS';
                        model.type = order.tipo;
                        model.isScheduling = order.scheduling;
                        model.schedulingStart = moment(order.requested_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");
                        model.schedulingFinish = dataHoraEntregaPrevista.format("YYYY-MM-DDTHH:mm:ss");
                        model.totalPrice = order.total_reference_price
                        model.totalDiscount = order.customer_total
                        model.totalPriceFinish = order.total_price
                        model.totalItems = order.items.length
                        model.items = order.items.map((item) => new Items(item.index, item.sku, item.name, item.quantity, item.price_un, item.price_total))
                        model.payments = []
                        model.domainUrl = "www.farmaciasapp.com.br";
                        model.fullDetails = order;

                        allOrders.push(model);
                    });

                });

                const modeltESTE = new OrderModel();
                modeltESTE.platform = 'Mercado Livre'; // Alterado para Mercado Livre
                modeltESTE.defaultColor = '#f6df00';
                modeltESTE.OrderIdPlatform = 'ML123456'; // ID de Pedido do Mercado Livre
                modeltESTE.dateTime = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"); // Data e hora do pedido
                modeltESTE.storeId = 'mlstore123'; // ID da loja no Mercado Livre
                modeltESTE.storeName = 'Drogaleste 51'; // Nome da loja no Mercado Livre
                modeltESTE.statusPlatform = 'EMI'; // Status do pedido no Mercado Livre
                modeltESTE.statusLiveship = 'EMI'; // Descrição do status do pedido
                modeltESTE.category = 'Novo'; // Categoria do pedido no Mercado Livre
                modeltESTE.orderId = 'MLORDER789afsdf'; // ID do pedido no Mercado Livre
                modeltESTE.isExpress = false; // Não é expresso
                modeltESTE.type = 'standard'; // Tipo de pedido (por exemplo, padrão)
                modeltESTE.isScheduling = false; // Não é agendado
                modeltESTE.schedulingStart = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"); // Não há agendamento de início
                modeltESTE.schedulingFinish = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"); // Não há agendamento de término
                modeltESTE.totalPrice = 150.50; // Preço total do pedido
                modeltESTE.totalDiscount = 10.00; // Desconto total aplicado
                modeltESTE.totalPriceFinish = 140.50; // Preço final do pedido após desconto
                modeltESTE.totalItems = 3; // Número total de itens no pedido
                modeltESTE.items = [];
                modeltESTE.payments = []; // Não há informações de pagamento disponíveis neste exemplo
                modeltESTE.domainUrl = "www.mercadolivre.com.br"; // URL do Mercado Livre
                modeltESTE.fullDetails = {}; // Detalhes completos do pedido (pode ser útil para debug ou rastreamento)
                allOrders.push(modeltESTE);

                const modelMagazineLuiza = new OrderModel();
                modelMagazineLuiza.platform = 'Magazine Luiza'; // Plataforma da Magazine Luiza
                modelMagazineLuiza.defaultColor = '#0086ff';
                modelMagazineLuiza.OrderIdPlatform = 'ML123456'; // ID de Pedido da Magazine Luiza (valor fictício)
                modelMagazineLuiza.dateTime = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"); // Data e hora do pedido
                modelMagazineLuiza.storeId = 'magazinestore123'; // ID da loja na Magazine Luiza (valor fictício)
                modelMagazineLuiza.storeName = 'Drogaleste 21'; // Nome da loja na Magazine Luiza
                modelMagazineLuiza.statusPlatform = 'GER'; // Status do pedido na Magazine Luiza (valor fictício)
                modelMagazineLuiza.statusLiveship = 'GER'; // Descrição do status do pedido na Magazine Luiza
                modelMagazineLuiza.category = 'Novo'; // Categoria do pedido na Magazine Luiza
                modelMagazineLuiza.orderId = 'MLORDER789'; // ID do pedido na Magazine Luiza (valor fictício)
                modelMagazineLuiza.isExpress = true; // Não é expresso
                modelMagazineLuiza.type = 'standard'; // Tipo de pedido (por exemplo, padrão)
                modelMagazineLuiza.isScheduling = true; // Não é agendado
                modelMagazineLuiza.schedulingStart = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");; // Não há agendamento de início
                modelMagazineLuiza.schedulingFinish = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");; // Não há agendamento de término
                modelMagazineLuiza.totalPrice = 150.50; // Preço total do pedido
                modelMagazineLuiza.totalDiscount = 10.00; // Desconto total aplicado
                modelMagazineLuiza.totalPriceFinish = 140.50; // Preço final do pedido após desconto
                modelMagazineLuiza.totalItems = 3; // Número total de itens no pedido
                modelMagazineLuiza.items = []; // Não há itens no pedido neste exemplo
                modelMagazineLuiza.payments = []; // Não há informações de pagamento disponíveis neste exemplo
                modelMagazineLuiza.domainUrl = "www.magazineluiza.com.br"; // URL da Magazine Luiza
                modelMagazineLuiza.fullDetails = {}; // Detalhes completos do pedido (pode ser útil para debug ou rastreamento)
                allOrders.push(modelMagazineLuiza); // Adiciona o modelo de pedido da Magazine Luiza ao array allOrders


                onDataUpdate(prevData => ({ ...prevData, farmaciasApp: allOrders }));
            },

            handleFarmaciasAppIntegrated: (response) => {
                console.log(response);
                onIntegratedUpdate(prevData => ({ ...prevData, farmaciasApp: response }));
            },

            handleIfood: (response) => {
                console.log(response)
                const allOrders = [];
                for (const order of response) {

                    const model = new OrderModel();
                    model.platform = 'Ifood';
                    model.defaultColor = '#ea1d2c';
                    model.OrderIdPlatform = order.parceiro.codigoPedido;
                    model.dateTime = moment(order.dataHora).format("YYYY-MM-DDTHH:mm:ss");
                    model.storeId = order.idLoja;
                    model.storeName = order.loja.nome;
                    model.statusPlatform = order.status;
                    model.statusLiveship = order.status;
                    model.category = getCategory(order.status);
                    model.orderId = order.codigo;
                    model.isExpress = order?.tipo === 'EXPRESS';
                    model.type = order.tipo;
                    model.isScheduling = order.parceiro.agendado;
                    model.schedulingStart = `${order?.agendamentoDataInicio.split('T')[0]}T${order?.agendamentoHoraInicio}`;
                    model.schedulingFinish = `${order?.agendamentoDataFim.split('T')[0]}T${order?.agendamentoHoraFim}`;
                    model.totalPrice = order.valorMercado || 0.00
                    model.totalDiscount = order.valorDesconto || 0.00
                    model.totalPriceFinish = order.valorCorrigido || order.valorTotal || 0.00
                    model.totalItems = order.items.length
                    model.items = order.items.map((item) => new Items(item.index, item.codigoLoja, item.produto, item.quantidade3, item.valor, item.valorTotal))
                    model.payments = []
                    model.domainUrl = "www.ifood.com.br";
                    model.deliveryAddress = [order?.enderecoEntrega?.latitude, order?.enderecoEntrega?.longitude]
                    model.fullDetails = order;

                    allOrders.push(model);
                }

                const modeltESTE = new OrderModel();
                modeltESTE.platform = 'Rappi'; // Alterado para Rappi
                modeltESTE.defaultColor = '#ff441f';
                modeltESTE.OrderIdPlatform = 'RP123456'; // ID de Pedido do Rappi (valor fictício)
                modeltESTE.dateTime = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"); // Data e hora do pedido
                modeltESTE.storeId = 'rappistore123'; // ID da loja no Rappi (valor fictício)
                modeltESTE.storeName = 'Drogaleste 81'; // Nome da loja no Rappi
                modeltESTE.statusPlatform = 'SEP'; // Status do pedido no Rappi (valor fictício)
                modeltESTE.statusLiveship = 'SEP'; // Descrição do status do pedido no Rappi
                modeltESTE.category = 'Recebido'; // Categoria do pedido no Rappi
                modeltESTE.orderId = 'RPORDER789'; // ID do pedido no Rappi (valor fictício)
                modeltESTE.isExpress = false; // Não é expresso
                modeltESTE.type = 'standard'; // Tipo de pedido (por exemplo, padrão)
                modeltESTE.isScheduling = false; // Não é agendado
                modeltESTE.schedulingStart = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");; // Não há agendamento de início
                modeltESTE.schedulingFinish = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");; // Não há agendamento de término
                modeltESTE.totalPrice = 150.50; // Preço total do pedido
                modeltESTE.totalDiscount = 10.00; // Desconto total aplicado
                modeltESTE.totalPriceFinish = 140.50; // Preço final do pedido após desconto
                modeltESTE.totalItems = 3; // Número total de itens no pedido
                modeltESTE.items = []; // Não há itens no pedido neste exemplo
                modeltESTE.payments = []; // Não há informações de pagamento disponíveis neste exemplo
                modeltESTE.domainUrl = "www.rappi.com.br"; // URL do Rappi
                modeltESTE.fullDetails = {}; // Detalhes completos do pedido (pode ser útil para debug ou rastreamento)
                allOrders.push(modeltESTE); // Adiciona o modelo de pedido Rappi ao array allOrders


                onDataUpdate(prevData => ({ ...prevData, ifood: allOrders }));
            },

            handleIfoodIntegrated: (response) => {
                onIntegratedUpdate(prevData => ({ ...prevData, ifood: response }));
                // onIntegratedUpdate(response);
            },

            handleDrogalesteApp: (response) => {

                const allOrders = [];
                for (const order of response) {

                    const model = new OrderModel();
                    model.platform = 'Drogaleste App';
                    model.defaultColor = '#ff441f';
                    model.OrderIdPlatform = order.codigoPedido;
                    model.dateTime = moment(order.dataHora).format("YYYY-MM-DDTHH:mm:ss");
                    model.storeId = order.loja.id;
                    model.storeName = order.loja.nome;
                    model.statusPlatform = order.status;
                    model.statusLiveship = order.status;
                    model.category = getCategory(order.status);
                    model.orderId = order.codigo;
                    model.isExpress = order?.tipo === 'EXPRESS';
                    model.type = order.tipo;
                    model.isScheduling = false;
                    model.schedulingStart = `${order?.agendamentoDataInicio.split('T')[0]}T${order?.agendamentoHoraInicio}`;
                    model.schedulingFinish = null;
                    model.totalPrice = order.valorTotal || 0.00
                    model.totalDiscount = order.valorTotal - order.valorCorrigido || 0.00
                    model.totalPriceFinish = order.valorCorrigido || 0.00
                    model.totalItems = order.items.length
                    model.items = order.items.map((item) => new Items(item.index, item.codigoBarra, item.produto, item.quantidade, item.valor, item.valorTotal))
                    model.payments = []
                    model.domainUrl = "www.drogaleste.com.br";
                    model.fullDetails = order;

                    allOrders.push(model);
                }

                const modeltESTE = new OrderModel();
                modeltESTE.platform = 'americanas'; // Alterado para Americanas
                modeltESTE.OrderIdPlatform = 'AMZ78910'; // ID de Pedido da Americanas (valor fictício)
                modeltESTE.dateTime = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss"); // Data e hora do pedido
                modeltESTE.storeId = 'amzstore456'; // ID da loja na Americanas (valor fictício)
                modeltESTE.storeName = 'Drogaleste 56'; // Nome da loja na Americanas
                modeltESTE.statusPlatform = 'FAT'; // Status do pedido na Americanas (valor fictício)
                modeltESTE.statusLiveship = 'FAT'; // Descrição do status do pedido na Americanas
                modeltESTE.category = 'Recebido'; // Categoria do pedido na Americanas
                modeltESTE.orderId = 'AMZORDER123'; // ID do pedido na Americanas (valor fictício)
                modeltESTE.isExpress = false; // Não é expresso
                modeltESTE.type = 'standard'; // Tipo de pedido (por exemplo, padrão)
                modeltESTE.isScheduling = false; // Não é agendado
                modeltESTE.schedulingStart = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");; // Não há agendamento de início
                modeltESTE.schedulingFinish = moment('2024-03-07 10:00:00', "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss");; // Não há agendamento de término
                modeltESTE.totalPrice = 150.50; // Preço total do pedido
                modeltESTE.totalDiscount = 10.00; // Desconto total aplicado
                modeltESTE.totalPriceFinish = 140.50; // Preço final do pedido após desconto
                modeltESTE.totalItems = 3; // Número total de itens no pedido
                modeltESTE.items = []; // Não há itens no pedido neste exemplo
                modeltESTE.payments = []; // Não há informações de pagamento disponíveis neste exemplo
                modeltESTE.domainUrl = "www.americanas.com.br"; // URL da Americanas
                modeltESTE.fullDetails = {}; // Detalhes completos do pedido (pode ser útil para debug ou rastreamento)
                allOrders.push(modeltESTE); // Adiciona o modelo de pedido Americanas ao array allOrders


                onDataUpdate(prevData => ({ ...prevData, drogalesteApp: allOrders }));
            },

            handleDrogalesteAppIntegrated: (response) => {
                onIntegratedUpdate(prevData => ({ ...prevData, drogalesteApp: response }));
                // onIntegratedUpdate(response);
            },


        };

        Object.keys(handleFunctions).forEach(eventName => {
            socket.on(eventName, handleFunctions[eventName]);
        });

        socket.on('connect', () => {
            console.log('Conectado ao servidor!');
        });

        socket.on('disconnect', () => {
            console.log('Desconectado do servidor!');
        });

        return () => {
            Object.keys(handleFunctions).forEach(eventName => {
                socket.off(eventName, handleFunctions[eventName]);
            });
            socket.disconnect();
        };
    }, [onDataUpdate]);
}


const getDescriptionStatus = (status) => {

    switch (status) {
        case 2:
            return 'CAN';
        case 4:
            return 'ENT';
        case 5:
            return 'FIN';
        case 6:
            return 'FAT';
        case 13:
            return 'PEN';
        case 14:
            return 'SEP';
        default:
            return 'ND';
    }
}

const getCategory = (status) => {
    if (status === 2 || status === 'CAN') {
        return 'Cancelado';
    } else if (status === 4 || status === 'ENT' || status === 'ENP' || status === 'RET') {
        return 'Entregando';
    } else if (status === 5 || status === 'FIN') {
        return 'Finalizado';
    } else if (status === 'EMI' || status === 'GER') {
        return 'Novo';
    } else if (status === 6 || status === 13 || status === 14 || status === 'SEP' || status === 'FAT') {
        return 'Recebido';
    } else {
        return 'ND';
    }
}


const getStoreName = (store) => {
    if (store < 100) {
        return `Drogaleste ${store}`
    } else {
        return `Nova Farma ${store}`
    }
}